import sharedStyles from "../../../global/SharedStyles";

export const styles = theme => ({

    ...sharedStyles(theme),

    form: {
        [theme.breakpoints.up('xs')]: {
            width: "100%"
        },

        [theme.breakpoints.up('sm')]: {
            width: "80%"
        },

        [theme.breakpoints.up('md')]: {
            width: "50%",

        },

        [theme.breakpoints.up('lg')]: {
            width: "40%",

        },

        position: "absolute",

        top: "20%",

        paddingLeft: "inherit",
        paddingRight: "inherit"

    },

    row: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },

    socialLoginButtonGroup: {},

    submitButton: {
        marginTop: theme.spacing.unit * 2,
        width: "100%"
    },

    socialButton: {
        width: "100%",
        marginBottom: theme.spacing.unit * 2
    },

    facebookButton: {

        color: theme.palette.primary.contrastText,
        backgroundColor: "#395697",
        '&:hover': { //copied from Button's source code
            backgroundColor: theme.palette.primary.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.primary.main
            }
        },
        verticalAlign: "bottom"
    },


    googleButton: {
        backgroundColor: theme.palette.common.white,
        verticalAlign: "bottom"
    },


    textField: {
        width: "100%",
    },

    optionDividerHalfLine: {
        display: "inline-block",
        width: "45%"
    },

    optionDividerText: {

        textAlign: "center",
        display: "inline-block",
        flexGrow: 1,  //to take up left space
        color: theme.palette.text.secondary,
        fontSize: "0.9rem"
    },

    takeUpSpace: {
        flexGrow: 1
    },

    loginOrSignUpSwitchPrompt: {
        fontSize: "0.9rem",
        justifyContent: "center"
    },

    forgotPasswordLink: {
        fontSize: "0.9rem",
        textDecoration: 'none',
        color: theme.palette.text.primary
    }


});