import {loadingStarted} from "../../../global/GlobalActions";
import {encodeForm} from "../../../util/HtmlHelper";
import {FACEBOOK_LOGIN, GOOGLE_LOGIN} from "../../../global/BackendUrls";
import axios from "axios/index";
import {newTokenFromBackendResponse, setTokenAction} from "../shared/TokenActions";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";

function loginWithGoogleAuthCodeAsyncAction(googleResponse) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            GOOGLE_LOGIN,

            encodeForm({
                username: googleResponse.code,
                password: "placeholder",
                grant_type: 'password',
                long_session: true
            }),

            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );

        promise.then(function (response) {
            dispatch(setTokenAction(newTokenFromBackendResponse(response)));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, null))
            .catch((error) => handleFetchError(error, dispatch, true));
    }
    return actionAsFunction;  //so the action itself is a function
}

function loginWithFacebookTokenAsyncAction(facebookResponse) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            FACEBOOK_LOGIN,

            encodeForm({
                username: facebookResponse.accessToken,
                password: "placeholder",
                grant_type: 'password',
                long_session: true
            }),

            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );

        promise.then(function (response) {
            dispatch(setTokenAction(newTokenFromBackendResponse(response)));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, "Login successful"))
            .catch((error) => handleFetchError(error, dispatch, true));
    }
    return actionAsFunction;  //so the action itself is a function
}


//non-action functions
export function handleGoogleAuthCodeSuccess(googleResponse, dispatch){
    console.log("Successfully got auth code from google. Below is google's response.")
    console.log(googleResponse);
    dispatch(loginWithGoogleAuthCodeAsyncAction(googleResponse));
}


export function handleGoogleAuthCodeFailure(googleResponse, dispatch){
    console.log("Failed to fetch auth code from google. Below is google's response.")
    console.log(googleResponse);
    handleFetchError(null, dispatch);
}


export function handleFacebookTokenSuccess(facebookResponse, dispatch){
    console.log("Successfully got access token from facebook. Below is facebook's response.")
    console.log(facebookResponse);
    dispatch(loginWithFacebookTokenAsyncAction(facebookResponse));
}


export function handleFacebookTokenFailure(facebookResponse, dispatch){
    console.log("Failed to fetch access token from facebook. Below is facebook's response.")
    console.log(facebookResponse);
    handleFetchError(null, dispatch);
}