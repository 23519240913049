import {loadingDone, loadingFailed, loadingStarted} from "./GlobalActions";
import {clearTokenAction} from "../pages/auth/shared/TokenActions";

//will return a promise that is already resolved, of which a resolve function will get the response as its parameter
//Recommended to be the final resolve
export const handleFetchSuccess = function (dispatch, userInfo) {
    return new Promise(function (resolveFunction, rejectFunction) {
        loadingDone(dispatch, userInfo);
        resolveFunction(userInfo);
    });
};

//will NOT return a promise
export const handleFetchError = function (error, dispatch, tokenRequest = false) {

    if(error && error.response && (!tokenRequest) && [400, 401, 403].includes(error.response.status)) {// "token error"
        // "See https://tools.ietf.org/html/rfc6750#page-7"
        if(error.response.headers){
            let authHeader = error.response.headers["www-authenticate"];
            console.log(`WWW-Authenticate: ${authHeader}`);
        }
        loadingFailed(dispatch,"Please login");
        dispatch(clearTokenAction());

    } else if (error && error.response && error.response.data) { //failure with http response, such as an http 500 response
        loadingFailed(dispatch,error.response.data.error_description_for_user || "Unknown Error");
    } else { //failure without http response
        console.log("error has no response");
        console.log(error);
        loadingFailed(dispatch,"Unknown Error");
    }
}


export function loadingTest(dispatch) {
    loadingStarted(dispatch);
    setTimeout(
        () => {
            if (Math.random() >= 0.5) {
                loadingFailed(dispatch,"You failed me!");
            } else {
                loadingDone(dispatch,"Everything fine");
            }

        },
        3000);
}
