const styles = (theme) => ({

    //The css for the content root (the layout is:  topBar, leftDrawer and the content)
    contentRoot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing.unit * 3
    }
});


export default styles;