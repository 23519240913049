

//actions
export const LOADING_STARTED = "loadingStarted";
export const LOADING_DONE = "loadingDone";
export const LOADING_FAILED = "loadingFailed";
export const CLEAR_USER_MESSAGE = "clearUserMessage";
export const DESTROY_REDUX_STATE = "destroyReduxState";

export const SET_PAGE_TITLE = "setPageTitle";


//action creators
export function setPageTitleAction(title){
    return {type: SET_PAGE_TITLE, title: title};
}

//action dispatchers
export function loadingStarted(dispatch) {
    dispatch({type: LOADING_STARTED});

}

export function loadingDone(dispatch, userInfo) {
    dispatch({type: LOADING_DONE, userInfo: userInfo});

}

export function loadingFailed(dispatch, userError) {
    dispatch({type: LOADING_FAILED, userError: userError});

}

export function clearUserMessage(dispatch) {
    dispatch({type: CLEAR_USER_MESSAGE});
}

export function destroyReduxState(dispatch) {
    dispatch({type: DESTROY_REDUX_STATE});
}



