import * as moment from 'moment';
//state
import {CLEAR_TOKEN, SET_TOKEN} from "./TokenActions";

const initialToken = {
    /*Only read them via selectors, because they may have been invalid*/
    access_token: null,
    expires_at_str: null, //type is string, cause it may be loaded from local storage, which only stores string
    user_principal: null

};


// selectors
export const hasValidToken = token => {
    const result = token && (!!(token.user_principal && token.access_token && token.expires_at_str && moment(token.expires_at_str) > moment()));
    return result;
};

export const getAccessToken = token => (
    hasValidToken(token) ? token.access_token : null
);

export const getUserPrincipal = token => (
    hasValidToken(token) ? token.user_principal : null
);


export const getUserDisplayName = function (token) {
    let principal = getUserPrincipal(token);
    if (!principal) {
        return "";
    }
    if (principal.startsWith("local:")) {
        return principal.substring("local:".length);
    } else {
        return principal;
    }
}


//the reducer
export function tokenReducer(state = initialToken, action) {
    switch (action.type) {
        case SET_TOKEN:
            return {
                access_token: action.token.access_token,
                expires_at_str: action.token.expires_at_str,
                user_principal: action.token.user_principal
            }
        case CLEAR_TOKEN:
            return {
                access_token: null,
                expires_at_str: null,
                user_principal: null
            }
        default:
            return state;
    }
}

