import {destroyReduxState, loadingFailed, loadingStarted} from "../../../global/GlobalActions";
import {LOGOUT} from "../../../global/BackendUrls";
import axios from "axios/index";
import {handleFetchSuccess} from "../../../global/GlobalHandlers";
import {getAccessToken} from "../shared/TokenReducers";
import {clearTokenAction} from "../shared/TokenActions";

export function logOutAsyncAction(token) {
    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);
        dispatch(clearTokenAction());
        destroyReduxState(dispatch); //destroy everything!

        const promise = axios.post(
            LOGOUT,
            null,
            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise
            .then(function (response) {
                return response;
            })
            .then(() => handleFetchSuccess(dispatch, "Logged out"))
            .catch(
                (error) => {
                    console.log(`log out failure because of ${error}`);
                    loadingFailed(dispatch, null);
                });
    }
    return actionAsFunction;  //so the action itself is a function
}

