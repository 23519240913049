//copied from https://stackoverflow.com/a/31615643/301447
export function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function isPositiveInteger(value) {
    return /^[1-9]\d*$/.test(value);
}

/**
 * "the first 3rd" or "the last 100th" ?
 * value: text
 *
 */
export const ORDINAL_TYPE = {
    FIRST: "first",
    LAST:  "last"
}