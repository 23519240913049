import {CLEAR_PROFILE_LOCALLY, SET_PROFILE_FROM_BACKEND_RESPONSE} from "./UserProfileActions";


//selector
export const hasUserProfile = function(state){
    return state.userProfile && state.userProfile.principal;
}

//the reducer
export function userProfileReducer(state = {}, action) {
    switch (action.type) {
        case SET_PROFILE_FROM_BACKEND_RESPONSE:
            return action.responseData;
        case CLEAR_PROFILE_LOCALLY:
            return {};
        default:
            return state;
    }
}

