import * as React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import {TextField as FormikTextField} from "formik-material-ui";
import {TextField} from "@material-ui/core";
import {toDoStyles} from './ToDoStyles';
import LoadingAwareButton from "../../uicomponents/LoadingAwareButton";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {PATH_TODO} from "../../global/FrontendUrls";
import Button from "@material-ui/core/Button/Button";
import classNames from "classnames";
import {InlineDatePicker} from 'material-ui-pickers';
import Switch from '@material-ui/core/Switch';
import {ITEM_TYPE, NEUTRAL_DATE_FORMAT} from "./ToDoConstants";
import {clearCurrentToDoItemLocallyAction, getCurrentToDoItemAsyncAction, newToDoItemAsyncAction, updateToDoItemAsyncAction} from "./ToDoActions";
import {isPositiveInteger} from "../../util/NumberHelper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToDoRepeatStrategyEditComponent, {validate as validateRepeatStrategyComponent} from "./ToDoRepeatStrategyEditComponent";
import {Field, Form, Formik} from 'formik';
import {BackendToDoRepeatStrategy, ToDoRepeatStrategy} from "./model/ToDoRepeatStrategy";
import queryString from 'query-string';
import moment from "moment";
import {dateToString} from "./ToDoUtils";


const REPEAT_STRATEGY_PATH_ROOT = "repeatStrategy";

class ToDoItemEditComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitSuccess: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.markFormSubmitSuccess = this.markFormSubmitSuccess.bind(this);
        this.validate = this.validate.bind(this);
    }


    getFormInitialValues(){
        const values =  this.props.currentItem ? {
                task: this.props.currentItem.task || "",
                moreInfo: this.props.currentItem.moreInfo || "",
                date: this.props.currentItem.date,  //moment.js date type
                repeatOn: !!this.props.currentItem.repeatStrategy,
                repeatStrategy: this.props.currentItem.repeatStrategy || new ToDoRepeatStrategy()
            } :
            {
                task: "",
                moreInfo: "",
                date: this.props.isAdd? this.props.intendedDate: null,  //moment.js date type
                repeatOn: false,
                repeatStrategy: new ToDoRepeatStrategy()
            }
         return values;
    }

    validate(values){
        let errors = {};

        const task = values.task.trim();
        if (!task) {
            errors.task = 'Task is required';
        } else if (task.length > 200) {
            errors.task = 'No more than 200 characters';
        }

        const moreInfo = values.moreInfo.trim();
        if(moreInfo && moreInfo.length > 5000){
            errors.moreInfo = 'No more than 5000 characters';
        }

        Object.assign(errors, validateRepeatStrategyComponent(values, REPEAT_STRATEGY_PATH_ROOT));

        return errors;
    };
    
    onSubmitFake(values) {
        console.log(values);
        this.markFormSubmitSuccess();
    }

    onSubmit(values) {
        const newTask = values.task.trim();
        const newMoreInfo = values.moreInfo.trim();
        const newDate = values.date;
        let repeatStrategy = null;

        if(values.repeatOn && values.repeatStrategy){
            repeatStrategy = BackendToDoRepeatStrategy.formModel2BackendModel(values.repeatStrategy);
        }

        if (this.props.isAdd) {
            this.props.newItem(this.props.token, newTask, newMoreInfo, newDate, repeatStrategy, this.markFormSubmitSuccess);
        } else {
            this.props.updateItem(this.props.token, this.props.currentItem.id, newTask, newMoreInfo, newDate, repeatStrategy, this.markFormSubmitSuccess);
        }
    }

    canRepeat(formValues){
        return formValues.date != null;
    }


    markFormSubmitSuccess() {
        this.setState({formSubmitSuccess: true});
    }

    render() {

        const isAdd = this.props.isAdd;
        const classes = this.props.classes;

        let isExistingSerialItem = (this.props.currentItem && this.props.currentItem.type === ITEM_TYPE.SERIAL);
        const dateLabel = isExistingSerialItem ? "Start Date": "Date";

        if (this.state.formSubmitSuccess) {
            return <Redirect to={{pathname: PATH_TODO}}/>
        }

        return (
            <Formik initialValues={this.getFormInitialValues()} onSubmit={this.onSubmit} validate={this.validate}>

                { ({values, setFieldValue, errors, touched}) => (
                <Form className={classes.contentRoot} method="post">

                    <div className={classes.row}>
                        <Field name="task" label="Task *" className={this.props.classes.textField} component={FormikTextField} />
                    </div>


                    <div className={classes.row}>
                        <Field name="moreInfo" multiline={true} label="More Info" className={this.props.classes.textField} component={FormikTextField}/>
                    </div>

                    <div className={classes.row}>
                        <Field label={dateLabel} value={values.date}  className={this.props.classes.textField}
                               component={InlineDatePicker} onlyCalendar={true} format={NEUTRAL_DATE_FORMAT}
                               onChange={(newDate) => setFieldValue("date", newDate)}/>
                    </div>

                    {isExistingSerialItem && this.props.currentItem.nextDate &&
                    <div className={classes.row}>
                        <Field label="Next Date" value={dateToString(this.props.currentItem.nextDate)}  className={this.props.classes.textField}
                               InputProps={{readOnly: true}}
                               component = {TextField}
                               />
                    </div>
                    }

                    {(isAdd || (this.props.currentItem && this.props.currentItem.type !== ITEM_TYPE.SERIAL_INSTANCE)) &&
                     <React.Fragment>
                        <div className={classes.row}>
                            <Field name="repeatOn">
                                {() => (
                                <FormControlLabel
                                    control={
                                        <Switch disabled={!this.canRepeat(values)} />
                                    }
                                    label={this.canRepeat(values)? "Repeat": "Repeat (please set the Date first)"}
                                    checked={values.repeatOn}
                                    onChange={(event) => setFieldValue("repeatOn", this.canRepeat(values) && event.target.checked )}
                                />
                                )}
                            </Field>
                        </div>

                        {values.repeatOn &&
                        <div className={classes.row}>
                            <ToDoRepeatStrategyEditComponent startDate={values.date}
                                                             pathRoot={REPEAT_STRATEGY_PATH_ROOT}
                                                             formValues={values}
                                                             formErrors={errors}
                                                             formTouched={touched}
                                                             setFieldValue={setFieldValue}/>
                        </div>
                        }
                     </React.Fragment>
                    }


                    <div className={this.props.classes.row}>

                        <LoadingAwareButton type="submit" variant="contained" color="primary"
                                            loading={this.props.loading}
                                            className={classNames(this.props.classes.formButton, this.props.classes.editItemFormSaveButton)}>
                            Save
                        </LoadingAwareButton>


                        <Button variant="contained" component={Link} to={PATH_TODO}
                                className={classNames(this.props.classes.formButton, this.props.classes.editItemFormCancelButton)}>
                            Cancel
                        </Button>

                        <div className={this.props.classes.takeUpSpace}/>

                    </div>

                </Form>
                )}
            </Formik>
        );
    }
}


ToDoItemEditComponent.propTypes = {
    loading: PropTypes.bool.isRequired,
    token: PropTypes.object,
    currentItem: PropTypes.object, //the item to edit
    isAdd: PropTypes.bool.isRequired,
    intendedDate: PropTypes.object, //moment.js
    newItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    loadItem: PropTypes.func,
    clearItemLocally: PropTypes.func
};

ToDoItemEditComponent = withStyles(toDoStyles)(ToDoItemEditComponent);


class ToDoItemEditComponentContainer extends React.Component {

    parseUrl(){
        const {match, location} = this.props;
        const isAdd =  match.path.endsWith("/new");
        const itemId = match.params.itemId;
        let intendedDate = null;

        if(isAdd){
            let qpMap = queryString.parse(location.search);
            if(qpMap.date){
                intendedDate = moment(qpMap.date, NEUTRAL_DATE_FORMAT)
            }
        }

        return {
            isAdd,
            intendedDate, //moment.js type
            itemId //if not adding
        }
    }


    componentDidMount() {
        const itemId = this.parseUrl().itemId;
        if (isPositiveInteger(itemId)) {
            this.props.loadItem(this.props.token, itemId);
        }
    }

    componentWillUnmount() {
        this.props.clearItemLocally();
    }

    render() {
        const {isAdd,intendedDate}  = this.parseUrl();
        if (isAdd) {
            return <ToDoItemEditComponent {...this.props} isAdd={true} intendedDate={intendedDate}/>;
        } else {
            if (this.props.currentItem) {
                return <ToDoItemEditComponent {...this.props} isAdd={false} currentItem={this.props.currentItem}/>;
            } else {
                return null;
            }
        }
    }
}


function mapStateToProps(state) {
    return {
        loading: state.global.loading,
        token: state.token,
        currentItem: state.toDo.currentItem
    };
}

function mapDispatchToProps(dispatch) {
    return {
        newItem: (token, task, moreInfo, date, repeatStrategy, successCallback) => dispatch(newToDoItemAsyncAction(token, task, moreInfo, date, repeatStrategy, successCallback)),
        updateItem: (token, itemId, task, moreInfo, date, repeatStrategy, successCallback) => dispatch(updateToDoItemAsyncAction(token, itemId, task, moreInfo, date, repeatStrategy, successCallback)),
        loadItem: (token, itemId) => dispatch(getCurrentToDoItemAsyncAction(token, itemId)),
        clearItemLocally: () => dispatch(clearCurrentToDoItemLocallyAction())
    }
}

ToDoItemEditComponentContainer = connect(mapStateToProps, mapDispatchToProps)(ToDoItemEditComponentContainer);

export default ToDoItemEditComponentContainer;