import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import CommonLink from '../../../uicomponents/CommonLink';
import Typography from '@material-ui/core/Typography';
import LoadingAwareButton from "../../../uicomponents/LoadingAwareButton";
import {styles} from "../shared/AuthenticationStyles";
import {SocialLoginFragmentContainer as SocialLoginFragment} from "../shared/SocialLoginFragment";
import {connect} from "react-redux";
import classNames from 'classnames';
import {signUpAsyncAction} from "./SignUpActions";
import {NEW_PASSWORD_REGEX, NEW_PASSWORD_REGEX_TIP} from "../shared/AuthenticationConstants";
import {Redirect} from "react-router-dom";
import {hasValidToken} from "../shared/TokenReducers";
import {PATH_HOME, PATH_LOGIN} from "../../../global/FrontendUrls";

class SignUpComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                email: "",
                password: "",
                repeatPassword: ""
            }
        }
        this.handleChange = this.handleChange.bind(this);

    }


    componentWillMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.password) {
                return false;
            }
            return true;
        });
    }

    handleChange(event) {
        this.setState({
            formData: Object.assign({}, this.state.formData, {[event.target.name]: event.target.value.trim()})
        });
    }


    render() {

        const {from} = this.props.location.state || {from: {pathname: PATH_HOME}};

        if (this.props.isLoggedIn) {
            return <Redirect to={from}/>
        }

        return (
            <div className={this.props.classes.contentRoot}>
                <ValidatorForm className={this.props.classes.form}
                               onSubmit={e => this.props.onSubmit(this.state.formData.email, this.state.formData.password)}>

                    <SocialLoginFragment classes={this.props.classes}/>


                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="email" label="Email" className={this.props.classes.textField}
                            value={this.state.formData.email} onChange={this.handleChange}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="password" label="Password" type="password" className={this.props.classes.textField}
                            value={this.state.formData.password} onChange={this.handleChange}
                            validators={['required', NEW_PASSWORD_REGEX]}
                            errorMessages={['Password is required', NEW_PASSWORD_REGEX_TIP]}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="repeatPassword" label="Repeat password" type="password"
                            className={this.props.classes.textField}
                            value={this.state.formData.repeatPassword} onChange={this.handleChange}
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={['Repeat password is required', 'Passwords mismatch']}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <LoadingAwareButton type="submit" variant="contained" color="primary"
                                            loading={this.props.loading}
                                            className={this.props.classes.submitButton}>Sign up</LoadingAwareButton>
                    </div>


                    <div className={classNames(this.props.classes.row, this.props.classes.loginOrSignUpSwitchPrompt)}>
                        <Typography gutterBottom noWrap align="center">
                            Already have an account? <CommonLink to={PATH_LOGIN}>Log in</CommonLink>
                        </Typography>
                    </div>


                </ValidatorForm>
            </div>
        );
    }

}

SignUpComponent = withStyles(styles)(SignUpComponent);


/** signup **/
function mapStateToProps_SignUp(state) {
    return {
        loading: state.global.loading,  //required for LoadingAwareButton
        isLoggedIn: hasValidToken(state.token)
    };
}

function mapDispatchToProps_SignUp(dispatch) {
    return {
        onSubmit: (email, password) => dispatch(signUpAsyncAction(email, password))
    };
}


export const SignUpComponentContainer = connect(
    mapStateToProps_SignUp,
    mapDispatchToProps_SignUp
)(SignUpComponent);
