import * as React from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";


import {verifyEmailRequestAsyncAction} from "./EmailActions";
import LoadingAwareButton from "../../../uicomponents/LoadingAwareButton";
import {errorColor, successColor} from "../../../framework/UiConstants";
import {SOURCE_LOCAL} from "../../../global/BizConstants";

const styles = theme => ({

    root: {
        display: "flex",
        flexWrap: "wrap"
    },

    row: {
        flexGrow: 1,
        flexBasis: "100%",
        display: "flex",
        marginTop: theme.spacing.unit * 0.5,
        marginBottom: theme.spacing.unit * 0.5,
    },

    takeUpSpace: {
        flexGrow: 1
    },

    verified: {
        color: successColor(theme)
    },

    notVerified: {
        color: errorColor(theme)
    },

    highlighted: {
        fontWeight: "bold"
    }


});

class EmailComponent extends React.Component {

    render() {

        return (
            <div className={this.props.classes.root}>

                <div className={this.props.classes.row}>
                    <h3>Email</h3>
                </div>

                <div className={this.props.classes.row}>


                 {this.props.userProfile.source === SOURCE_LOCAL &&
                        <div>
                            You registered as <span className={this.props.classes.highlighted}>{this.props.userProfile.email}</span>
                        </div>
                 }

                 {this.props.userProfile.source !== SOURCE_LOCAL &&
                    <div>
                        You connected as <span className={this.props.classes.highlighted}> {this.props.userProfile.email} </span>
                        from <span className={this.props.classes.highlighted}> {this.props.userProfile.source} </span>
                    </div>
                 }


                    <div className={this.props.classes.takeUpSpace}/>


                    <div className={this.props.userProfile.emailVerified? this.props.classes.verified: this.props.classes.notVerified}>
                        {this.props.userProfile.emailVerified ? "Verified":"Not verified"}
                    </div>


                </div>


                {!this.props.userProfile.emailVerified &&
                    <div className={this.props.classes.row}>

                        <div className={this.props.classes.takeUpSpace}/>

                        <LoadingAwareButton variant="contained" color="primary"
                                            loading={this.props.loading}
                                            onClick={e => this.props.onVerify(this.props.token)}>
                            Verify Email
                        </LoadingAwareButton>
                    </div>
                }
            </div>

        );

    }
}


EmailComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired,
    onVerify: PropTypes.func.isRequired,
    loading: PropTypes.bool

};
EmailComponent = withStyles(styles)(EmailComponent);

const mapStateToProps = state => {
    return {
        userProfile: state.userProfile,
        token: state.token,
        loading: state.global.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onVerify: token => dispatch(verifyEmailRequestAsyncAction(token))
    }
};

const EmailComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailComponent);


export default EmailComponentContainer;