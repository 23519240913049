import {loadingStarted} from "../../../global/GlobalActions";
import axios from "axios/index";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";
import {getAccessToken} from "../../auth/shared/TokenReducers";
import {CHANGE_PASSWORD} from "../../../global/BackendUrls";

export function changePasswordAsyncAction(currentPassword, newPassword, token, additionalCallback) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            CHANGE_PASSWORD,

            JSON.stringify({
                currentPassword: currentPassword,
                newPassword: newPassword
            }),

            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise
            .then(() => handleFetchSuccess(dispatch, "Password changed"))
            .then(additionalCallback)
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}
