import React from "react";
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = theme => ({
    defaultStyle: {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.light
        }
    }
});

class CommonLink extends React.Component {

    render() {
        const className = this.props.className ? this.props.className : this.props.classes.defaultStyle;
        return (<Link {...this.props} className={className}/>);
    }
}

CommonLink.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string
}

export default withStyles(styles)(CommonLink);