import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import {withStyles} from "@material-ui/core/styles";
import {drawerWidth} from "./UiConstants";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from '@material-ui/icons/Person';
import {Link} from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import {PATH_LOGIN, PATH_USER_PROFILE} from "../global/FrontendUrls";

const styles = (theme) => ({

    appBar: {
        marginLeft: drawerWidth,

        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },

        position: "absolute"
    },

    takeUpSpace: {
        flexGrow: 1
    },

    userInfoMenuItem: {
        outline: 'none',
        fontWeight: "bold"
    }

});


class TopBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileMenuOpen: false,
            profileMenuAnchor: null,
        };

    }


    handleProfileMenuClose = () => {
        this.setState({profileMenuOpen: false});
    };

    handleProfileMenuOpen = () => {
        this.setState({profileMenuOpen: true});
    };

    logOut = () => {
        this.props.onLogOut();
        this.handleProfileMenuClose();
    };


    render() {

        return (
            <AppBar className={this.props.classes.appBar}>
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton color="inherit" onClick={this.props.menuOnClick}>
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>

                    <Typography color="inherit" variant="title" noWrap>{this.props.pageTitle}</Typography>

                    <div className={this.props.classes.takeUpSpace}/>

                    {!this.props.isLoggedIn &&
                    <Button color="inherit" component={Link} to={PATH_LOGIN}>Log In</Button>
                    }

                    {this.props.isLoggedIn &&
                    <IconButton color="inherit"
                                onClick={this.handleProfileMenuOpen}
                                buttonRef={node => {
                                    this.profileMenuAnchor = node;
                                    }
                                }>
                        <PersonIcon/>
                    </IconButton>
                    }

                    <Menu
                        id="simple-menu"
                        anchorEl={this.profileMenuAnchor}
                        open={this.state.profileMenuOpen}
                        onClose={this.handleProfileMenuClose}
                    >
                        <MenuItem button={false} className={this.props.classes.userInfoMenuItem}>
                            {this.props.userDisplayName}
                        </MenuItem>
                        <Divider/>

                        <MenuItem component={Link} to={PATH_USER_PROFILE} onClick={this.handleProfileMenuClose}>Profile</MenuItem>
                        <MenuItem component={Link} to="#" onClick={this.logOut}>Log out</MenuItem>
                    </Menu>

                </Toolbar>

            </AppBar>
        )
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    userDisplayName: PropTypes.string,
    pageTitle: PropTypes.string,
    onLogOut: PropTypes.func.isRequired
}

export default withStyles(styles)(TopBar);
