import {loadingStarted} from "../../../global/GlobalActions";
import axios from "axios/index";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";
import {getAccessToken} from "../../auth/shared/TokenReducers";
import {START_EMAIL_VERIFICATION} from "../../../global/BackendUrls";


export function verifyEmailRequestAsyncAction(token) {
    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            START_EMAIL_VERIFICATION,
            null,
            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise
            .then(() => handleFetchSuccess(dispatch, "A verification link has been sent to your email address"))
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}

