import moment from "moment";
import {NEUTRAL_DATE_FORMAT} from "./ToDoConstants";
import {getNumberWithOrdinal} from "../../util/NumberHelper";

export function backendDateToMoment(backDateStr) {
    if (backDateStr == null) {
        return null;
    }
    return moment(backDateStr, NEUTRAL_DATE_FORMAT);
}


export function dateToString(dateMoment) {
    if (dateMoment == null) {
        return null;
    }
    return dateMoment.format(NEUTRAL_DATE_FORMAT);
}

export function dateToSmartText(dateMoment) {

    if (dateMoment == null) {
        return null;
    }

    let clientCurrentDate = getClientCurrentDate();
    if (dateMoment.diff(clientCurrentDate, 'days') === 0) {
        return "Today";
    }

    if (dateMoment.diff(clientCurrentDate, 'days') === 1) {
        return "Tomorrow";
    }

    return dateToString(dateMoment);
}

export function isOverdue(dateMoment) {

    if (dateMoment == null) {
        return false;
    }
    let clientCurrentDate = getClientCurrentDate();
    return clientCurrentDate.diff(dateMoment, 'days') > 0;

}

export function getClientCurrentDate() {
    return moment().startOf('day');
}

// return {1:"1st", 2:"2nd", ... }
export function getOrdinalNumberMap(end){
    const result = {};
    for(let i = 1; i <= end; i++){
        result[i] = getNumberWithOrdinal(i);
    }
    return result;
}

