import React from 'react';
import ReactDOM from 'react-dom';
import AppComponent from './framework/AppComponent';
import thunkMiddleware from 'redux-thunk';
import MomentUtils from '@date-io/moment';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {Provider} from 'react-redux'
import {composeWithDevTools} from 'redux-devtools-extension';
import {globalStateReducer} from './global/GlobalReducers'
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import {reducer as formReducer} from 'redux-form'
//import logger from 'redux-logger'
import {tokenReducer} from "./pages/auth/shared/TokenReducers";

import persistState, {mergePersistedState} from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import {userProfileReducer} from "./pages/user/profile/UserProfileReducers";
import {DESTROY_REDUX_STATE} from "./global/GlobalActions";
import {toDoReducer} from "./pages/toDo/ToDoReducers";
import {ErrorBoundaryComponent} from "./global/ErrorBoundaryComponent";


// the reducer, which deals with state/action only
let appReducer = combineReducers({
    global: globalStateReducer,
    token: tokenReducer,
    toDo: toDoReducer,
    userProfile: userProfileReducer,
    form: formReducer
});

appReducer = compose(  //for persistence such as local-storage
    mergePersistedState()
)(appReducer);

const storage = compose( //for persistence such as local-storage
    filter('token') //save token to storage
)(adapter(window.localStorage));


//See https://stackoverflow.com/a/35641992/301447
const rootReducer = (state, action) => {
    if (action.type === DESTROY_REDUX_STATE) {
        state = undefined;
    }

    return appReducer(state, action)
}


// create a store using the reducer above
const rootStore = createStore(rootReducer,
    composeWithDevTools(
        applyMiddleware(thunkMiddleware), //put "logger" in if you want to enable redux-logger
        persistState(storage, 'reduxStorage')  //for persistence such as local-storage
    )
);

//the listener. Normally it can be used to change the display of a component. So we call it "render()"
function render() {

    ReactDOM.render(
        <Provider store={rootStore}>
            <ErrorBoundaryComponent>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <AppComponent/>
                </MuiPickersUtilsProvider>
            </ErrorBoundaryComponent>
        </Provider>,

        document.getElementById('root'));
}

//// let the listener subscribe the change of state
rootStore.subscribe(render);

//Initial page rendering:
render();

// disable service worker because it can lead cache staying in browser forever
// registerServiceWorker();
