import React from "react";
import Button from "@material-ui/core/Button/index";
import {FacebookIcon, GoogleIcon} from "../../../framework/Icons";
import classNames from 'classnames';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import PropTypes from "prop-types";
import {handleFacebookTokenFailure, handleFacebookTokenSuccess, handleGoogleAuthCodeFailure, handleGoogleAuthCodeSuccess} from "./SocialLoginActions";
import {connect} from "react-redux";


//for embedding only
const SocialLoginFragment = (props) => (
    <React.Fragment>

        <div className={classNames(props.classes.row, props.classes.socialLoginButtonGroup)}>

            <GoogleLogin
                clientId="605790094475-bpvbjt86odkp881ea38lumnhbqdf77j6.apps.googleusercontent.com"
                responseType="code"
                render={renderProps => (
                    <Button onClick={renderProps.onClick} variant="contained" className={classNames(props.classes.socialButton, props.classes.googleButton)}>
                        <GoogleIcon/> Google
                    </Button>
                )}
                onSuccess={googleResponse => handleGoogleAuthCodeSuccess(googleResponse, props.dispatch)}
                onFailure={googleResponse => handleGoogleAuthCodeFailure(googleResponse, props.dispatch)}
            />

        </div>


        <div className={props.classes.row}>

            <hr className={props.classes.optionDividerHalfLine}/>
            <div className={props.classes.optionDividerText}>
                OR
            </div>

            <hr className={props.classes.optionDividerHalfLine}/>

        </div>

    </React.Fragment>
);


SocialLoginFragment.propTypes = {
    classes: PropTypes.object.isRequired //the classes used by the container component
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch:dispatch
    };
}


export const SocialLoginFragmentContainer = connect(
    null,
    mapDispatchToProps
)(SocialLoginFragment);