import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import ToDoListComponent from '../pages/toDo/ToDoListComponent';
import {NoPageFoundComponent} from '../pages/404/NoPageFoundComponent';
import {withStyles} from '@material-ui/core/styles';
import SideDrawer from "./SideDrawer";
import TopBar from "./TopBar";
import MessageBar from "./MessageBar";
import {clearUserMessage} from "../global/GlobalActions";
import {connect} from "react-redux";
import {HomeComponent} from "../pages/home/HomeComponent";
import {LoginComponentContainer as LoginComponent} from "../pages/auth/login/LoginComponent";
import {SignUpComponentContainer as SignUpComponent} from "../pages/auth/signup/SignUpComponent";
import {logOutAsyncAction} from "../pages/auth/logout/LogOutActions";
import {getUserDisplayName, hasValidToken} from "../pages/auth/shared/TokenReducers";
import UserProfileComponent from "../pages/user/profile/UserProfileComponent"
import {isBlank} from "../util/StringHelper";
import {PATH_FORGET_PASSWORD, PATH_HOME, PATH_LOGIN, PATH_SIGN_UP, PATH_TODO, PATH_TODO_ADD_ITEM, PATH_TODO_UPDATE_ITEM, PATH_USER_PROFILE} from "../global/FrontendUrls";
import MyRoute from "../global/MyRouteComponent";
import ForgetPasswordComponent from '../pages/auth/forget-password/ForgetPasswordComponent'
import LinearProgress from '@material-ui/core/LinearProgress';
import ToDoItemEditComponent from "../pages/toDo/ToDoItemEditComponent";

const styles = (theme) => ({
    app: {
        display: "flex"
    },

    toolbar: theme.mixins.toolbar,  //This is just used to generate a height equaling that of a toolbar

    main: {
        width: "100%"
    }
});


class AppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {tempDrawerOpen: false};
        this.toggleTempDrawer = this.toggleTempDrawer.bind(this);
    }

    toggleTempDrawer() {
        this.setState({tempDrawerOpen: !this.state.tempDrawerOpen});
    }

    tempDrawerOnClose() {
        this.setState({tempDrawerOpen: false});
    }


    render() {

        return (
            <Router>
                <div className={this.props.classes.app}>

                    <TopBar menuOnClick={this.toggleTempDrawer}
                            isLoggedIn={hasValidToken(this.props.token)}
                            userDisplayName={getUserDisplayName(this.props.token)}
                            pageTitle={this.props.pageTitle}
                            onLogOut={e => this.props.onLogOut(this.props.token)}
                    />

                    <SideDrawer
                        tempDrawerOpen={this.state.tempDrawerOpen}
                        tempDrawerOnClose={() => this.tempDrawerOnClose()}
                        isLoggedIn={hasValidToken(this.props.token)}
                    />

                    <main className={this.props.classes.main}>
                        <div className={this.props.classes.toolbar}/>
                        {/*Because top bar is using fixed position*/}


                        {this.props.globalState.loading &&  <LinearProgress color="secondary" /> }

                        <div id="contentParent">
                            <Switch>
                                <MyRoute exact path={PATH_HOME} component={HomeComponent} pageTitle="Home"/>
                                <MyRoute path={PATH_LOGIN} component={LoginComponent} pageTitle="Login"/>
                                <MyRoute path={PATH_FORGET_PASSWORD} component={ForgetPasswordComponent} pageTitle="Forget Password"/>
                                <MyRoute path={PATH_SIGN_UP} component={SignUpComponent} pageTitle="Sign Up"/>
                                <MyRoute path={PATH_USER_PROFILE} component={UserProfileComponent} requireLogin={true} pageTitle="User Profile"/>
                                <MyRoute exact path={PATH_TODO} component={ToDoListComponent}  requireLogin={true} pageTitle="Things to Do"/>
                                <MyRoute path={PATH_TODO_ADD_ITEM} component={ToDoItemEditComponent}  requireLogin={true} pageTitle="New To-do Item"/>
                                <MyRoute path={PATH_TODO_UPDATE_ITEM} component={ToDoItemEditComponent}  requireLogin={true} pageTitle="Update To-do Item"/>
                                <Route component={NoPageFoundComponent}/>
                            </Switch>
                        </div>

                    </main>

                    <MessageBar
                        message={this.props.globalState.userError || this.props.globalState.userInfo}
                        messageLevel={isBlank(this.props.globalState.userError) ? "success" : "error"}
                        onClose={this.props.onMessageBarClose}/>


                </div>
            </Router>

        );
    }
}


const mapStateToProps = state => {
    return {
        globalState: state.global,
        token: state.token,
        loading: state.global.loading,
        pageTitle: state.global.pageTitle
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onMessageBarClose: () => clearUserMessage(dispatch),
        onLogOut: (token) => dispatch(logOutAsyncAction(token))
    }
};

AppComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppComponent);

export default withStyles(styles)(AppComponent);
