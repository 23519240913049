import * as React from "react";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import {dateToString, isOverdue} from "./ToDoUtils";
import {warningColor} from "../../framework/UiConstants";
import {getToDoUpdateItemPath} from "../../global/FrontendUrls";
import CommonLink from "../../uicomponents/CommonLink";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import {DATE_FILTERS, ITEM_TYPE} from "./ToDoConstants";


const styles = (theme) => ({
    overdue: {
        color: warningColor(theme),
    },

    listItemText: {
        display: "inline-block"
    },

    listItemPrimary_INDEPENDENT: {

    },

    listItemPrimary_SERIAL: {
        fontWeight: 550
    },

    listItemPrimary_SERIAL_INSTANCE: {
        fontStyle: "italic"
    }

});


class ToDoItemComponent extends React.Component {

    constructor(props) {
        super(props);
        this.handleFinish = this.handleFinish.bind(this);
    }


    handleFinish(item) {
        this.props.onItemFinish(item);
    }

    itemDateToText(item) {
        let date = item.date;
        if(item.type === ITEM_TYPE.SERIAL){
            date = item.nextDate;
        }

        if(!date){
            return null;
        }

        if (isOverdue(date)) {
            return dateToString(date);
        }

        if (this.props.currentDateFilter === DATE_FILTERS.FUTURE) {
            return dateToString(date);
        }

        return null;
    }

    render() {
        const {classes} = this.props;
        const item = this.props.item;
        const primaryTextClass =  classes[`listItemPrimary_${item.type}`];
        let secondaryTextClass = (item.type !== ITEM_TYPE.SERIAL && isOverdue(item.date)) ? classes.overdue : null;
        return (
            <ListItem className={classes.rowInsideList}>
                <Checkbox disableRipple onChange={event => this.handleFinish(item)}/>

                <CommonLink to={getToDoUpdateItemPath(item.id)}>
                    <ListItemText primary={item.task}
                                  secondary={this.itemDateToText(item)}
                                  classes={{
                                      root: classes.listItemText,
                                      primary: primaryTextClass,
                                      secondary: secondaryTextClass
                                  }}
                    />

                </CommonLink>

                {item.moreInfo &&
                <ListItemIcon>
                    <DescriptionOutlinedIcon/>
                </ListItemIcon>
                }

            </ListItem>
        );
    }
}


ToDoItemComponent.propTypes = {
    item: PropTypes.object.isRequired,
    currentDateFilter: PropTypes.string.isRequired,
    onItemFinish: PropTypes.func.isRequired
};

ToDoItemComponent = withStyles(styles)(ToDoItemComponent);


export default ToDoItemComponent;