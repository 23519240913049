import {CLEAR_USER_MESSAGE, LOADING_DONE, LOADING_FAILED, LOADING_STARTED, SET_PAGE_TITLE} from "./GlobalActions";

const initialGlobalState = {
    loading: false,
    userInfo: null,
    userError: null,
    pageTitle: ""
}

export function globalStateReducer(state = initialGlobalState, action) {

    switch (action.type) {
        case LOADING_STARTED: {
            return {
                ...state,
                loading: true,
                userInfo: null,
                userError: null
            }
        }
        case LOADING_DONE: {
            return {
                ...state,
                loading: false,
                userInfo: action.userInfo,
                userError: null
            }
        }
        case LOADING_FAILED: {
            return {
                ...state,
                loading: false,
                userInfo: null,
                userError: action.userError
            }
        }
        case CLEAR_USER_MESSAGE: {
            return {
                ...state,
                userInfo: null,
                userError: null
            }
        }

        case SET_PAGE_TITLE: {
            return {
                ...state,
                pageTitle: action.title
            }
        }

        default: {
            return state;
        }
    }

}