import React from 'react'
import PropTypes from "prop-types";
import {Link, Route} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const ListLinkItem = (props) => (
    <Route exact
           path={props.path}
           children={({match}) => (

               <ListItem button component={Link} to={props.path} onClick={props.onClick}>
                   <ListItemIcon>
                       <props.icon className={match ? props.primaryActiveClassName : ''}/>
                   </ListItemIcon>
                   <ListItemText
                       classes={match ? {primary: props.primaryActiveClassName} : {}}>
                       {props.primaryText}
                   </ListItemText>
               </ListItem>
           )}
    />
);

ListLinkItem.propTypes = {
    path: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    primaryActiveClassName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default ListLinkItem;