import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import TodayIcon from '@material-ui/icons/Today';
import HomeIcon from '@material-ui/icons/Home';
import {withStyles} from "@material-ui/core/styles";
import {drawerWidth} from "./UiConstants";
import ListLinkItem from '../uicomponents/LinkListItem';
import Divider from '@material-ui/core/Divider';
import {PATH_HOME, PATH_TODO} from "../global/FrontendUrls";

const styles = theme => ({
    drawer: {
        width: drawerWidth
    },

    drawerPaper: {
        width: drawerWidth,    //this should have been a prop, but Material-UI doesn't support reading props in styles for now            
    },

    selectedItem: {
        color: theme.palette.primary.main
    }
});


class SideDrawer extends React.Component {


    render() {

        const drawerContent = (

            <React.Fragment>
                <div className={this.props.classes.toolbar}/>


                <Divider/>
                <List>
                    <ListLinkItem path={PATH_HOME} primaryText="Home" icon={HomeIcon}
                                  primaryActiveClassName={this.props.classes.selectedItem}
                                  onClick={this.props.tempDrawerOnClose}/>

                    {this.props.isLoggedIn &&

                    <React.Fragment>

                        < ListLinkItem path={PATH_TODO} primaryText="To Do" icon={TodayIcon}
                                       primaryActiveClassName={this.props.classes.selectedItem}
                                       onClick={this.props.tempDrawerOnClose}/>

                    </React.Fragment>

                    }

                </List>
            </React.Fragment>
        );

        const drawerClasses = {docked: this.props.classes.drawer, paper: this.props.classes.drawerPaper};

        return (
            <React.Fragment>
                <Hidden smDown>
                    <Drawer variant="permanent" classes={drawerClasses}>
                        {drawerContent}
                    </Drawer>
                </Hidden>
                <Hidden mdUp>
                    <Drawer variant="temporary" classes={{paper: this.props.classes.drawerPaper}}
                            open={this.props.tempDrawerOpen} onClose={this.props.tempDrawerOnClose}>
                        {drawerContent}
                    </Drawer>
                </Hidden>
            </React.Fragment>
        )
    }
}

SideDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    tempDrawerOpen: PropTypes.bool.isRequired,
    tempDrawerOnClose: PropTypes.func,
    isLoggedIn: PropTypes.bool.isRequired
}

export default withStyles(styles)(SideDrawer);
