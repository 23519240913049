import React from 'react';
import sharedStyles from "../../global/SharedStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import {Redirect} from "react-router-dom";
import {PATH_TODO} from "../../global/FrontendUrls";

const styles = theme => ({
    ...sharedStyles(theme)
});

class HomeComponent extends React.Component {
    render() {
        // return (<div className={this.props.classes.contentRoot}><h1>Go Clumsy!</h1></div>);
        return <Redirect to={PATH_TODO}/>
    }
}

HomeComponent = withStyles(styles)(HomeComponent);

export {HomeComponent};