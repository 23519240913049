import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import LoadingAwareButton from "../../../uicomponents/LoadingAwareButton";

import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";
import * as PropTypes from "prop-types";
import {changePasswordAsyncAction} from "./ChangePasswordActions";
import {NEW_PASSWORD_REGEX, NEW_PASSWORD_REGEX_TIP} from "../../auth/shared/AuthenticationConstants";


const styles = theme => ({

    row: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

    },


    submitButton: {
        marginTop: theme.spacing.unit * 2,
        width: "100%"
    },

    textField: {
        width: "100%",
    }

});

class ChangePasswordComponent extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            showForm: false,
            formData: {
                currentPassword: "",
                newPassword: "",
                repeatPassword: ""
            }
        }

        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.newPassword) {
                return false;
            }
            return true;
        });

        this.handleChange = this.handleChange.bind(this);
        this.showForm = this.showForm.bind(this);
        this.hideForm = this.hideForm.bind(this);
        this.submitForm = this.submitForm.bind(this);

    }

    showForm(event) {
        this.setState({showForm: true});
    }

    hideForm(event) {
        this.setState({showForm: false});
    }

    handleChange(event) {
        this.setState({
            formData: Object.assign({}, this.state.formData, {[event.target.name]: event.target.value.trim()})
        });
    }

    submitForm() {
        this.props.dispatch(changePasswordAsyncAction(this.state.formData.currentPassword, this.state.formData.newPassword, this.props.token, this.hideForm));
    }


    render() {

        return (

            <div>

                <h3>Password</h3>

                {this.state.showForm &&

                <ValidatorForm onSubmit={this.submitForm}>

                    <h4>Change Password</h4>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="currentPassword" label="Current Password" type="password" className={this.props.classes.textField}
                            value={this.state.formData.currentPassword} onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['Current password is required']}
                        />
                    </div>


                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="newPassword" label="New Password" type="password" className={this.props.classes.textField}
                            value={this.state.formData.newPassword} onChange={this.handleChange}
                            validators={['required', NEW_PASSWORD_REGEX]}
                            errorMessages={['New password is required', NEW_PASSWORD_REGEX_TIP]}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="repeatPassword" label="Repeat password" type="password"
                            className={this.props.classes.textField}
                            value={this.state.formData.repeatPassword} onChange={this.handleChange}
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={['Repeat password is required', 'Password mismatch']}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <LoadingAwareButton type="submit" variant="contained" color="primary"
                                            loading={this.props.loading}
                                            className={this.props.classes.submitButton}>Submit</LoadingAwareButton>
                    </div>

                </ValidatorForm>
                }


                {!this.state.showForm &&
                <div className={this.props.classes.row}>
                    <Button variant="contained" color="primary" onClick={this.showForm}>
                        Change Password
                    </Button>
                </div>
                }
            </div>
        );
    }

}

ChangePasswordComponent = withStyles(styles)(ChangePasswordComponent);

ChangePasswordComponent.propTypes = {
    loading: PropTypes.bool,
    token: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        loading: state.global.loading,
        token: state.token
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch: dispatch}
}

const ChangePasswordComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordComponent);

export default ChangePasswordComponentContainer;