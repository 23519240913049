import {loadingStarted} from "../../../global/GlobalActions";
import {encodeForm} from "../../../util/HtmlHelper";
import {SIGN_UP} from "../../../global/BackendUrls";
import axios from "axios/index";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";
import {newTokenFromBackendResponse, setTokenAction} from "../shared/TokenActions";

export function signUpAsyncAction(email, password) {
    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            SIGN_UP,

            encodeForm({
                username: email,
                password: password,
                grant_type: 'password'
            }),

            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );

        promise.then(function (response) {
            dispatch(setTokenAction(newTokenFromBackendResponse(response)));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, "Signup successful"))
            .catch((error) => handleFetchError(error, dispatch, true));
    }
    return actionAsFunction;  //so the action itself is a function
}