import {DAYS_OF_WEEK, PERIOD_UNIT} from "../../../util/DateHelper";
import {getOrdinalNumberMap} from "../ToDoUtils";
import {invert} from 'lodash';

//VALUE:VALUE
export const REPEAT_MODE = {
    PERIOD: "PERIOD", //like "every 2 months"
    DAYS_OF_WEEK: "DAYS_OF_WEEK", //some day(s) in every week
    DAY_IN_MONTH: "DAY_IN_MONTH", //the first or last nTh day in every month, or the first "monday" in every month
}

export class ToDoRepeatStrategy {

    /**
     *
     * // @param endDate    date in moment.js
     * @param repeatMode  See {@link REPEAT_MODE}

     */
    constructor(repeatMode){
        //this.endDate = endDate;
        this.repeatMode = repeatMode || REPEAT_MODE.PERIOD;

        //Actually one of the following makes sense, depending on the repeatMode
        //This is done this way to make sure all nested objects are initialised, so a lot of null checking can be saved
        this.periodModeDetail = new PeriodModeDetail();
        this.daysOfWeekModeDetail = new DaysOfWeekModeDetail();
        this.dayInMonthModeDetail  = new DayInMonthModeDetail();
    }
}

class RepeatModeDetail{

    constructor(backendTypeName) {
        this.backendTypeName = backendTypeName;  //only needed when sending data to the backend
    }

}



const DAY_UNIT_KEY = invert(PERIOD_UNIT)[PERIOD_UNIT.DAY]; //get the spelling of key
class PeriodModeDetail extends RepeatModeDetail{
    /**
     *
     * @param unit  See {@link PERIOD_UNIT}
     * @param amount
     */
    constructor(unit, amount = 1){
        super("Period");
        this.unit = unit || DAY_UNIT_KEY;
        this.amount = amount;
    }
}

class DaysOfWeekModeDetail extends RepeatModeDetail{
    /**
     *
     * @param daysOfWeek  See {@link DAYS_OF_WEEK}. Type is {@link Set}
     */
    constructor(daysOfWeek){
        super("DaysOfWeek");
        this.daysOfWeek = daysOfWeek || []; //did not use Set here because Set won't work in JSON.stringify()
    }
}

//value: value
export const DAY_IN_MONTH_TYPE = {
    "FIRST_NTH_DAY": "FIRST_NTH_DAY",
    "LAST_NTH_DAY": "LAST_NTH_DAY",
    "FIRST_NTH_DAY_OF_WEEK": "FIRST_NTH_DAY_OF_WEEK",
    "LAST_NTH_DAY_OF_WEEK": "LAST_NTH_DAY_OF_WEEK",
}

//cannot be used directly with the backend
class DayInMonthModeDetail extends RepeatModeDetail{
    /**
     *
     * @param type  See {@link DAY_IN_MONTH_TYPE}
     */
    constructor(type){
        super("DayInMonth");
        this.type = type || DAY_IN_MONTH_TYPE.FIRST_NTH_DAY;
        //Only of the following makes sense, depending on the type
        //This is done this way to make sure all nested objects are initialised, so a lot of null checking can be saved
        this.firstNthDayOfMonthSpec = new DayOfMonthSpec();
        this.lastNthDayOfMonthSpec = new DayOfMonthSpec();
        this.firstNthDayOfWeekOfMonthSpec = new DayOfWeekOfMonthSpec();
        this.lastNthDayOfWeekOfMonthSpec = new DayOfWeekOfMonthSpec();
    }
}

/**
 * The details under Day-in-month mode
 */
class DimSpec {
    constructor(backendTypeName) {
        this.backendTypeName = backendTypeName;  //only needed when sending data to the backend
    }
}

class DayOfMonthSpec extends DimSpec{
    constructor(ordinalValue){
        super("DayOfMonth");
        this.ordinalValue = ordinalValue || 1;
    }
}

const MONDAY_KEY = invert(DAYS_OF_WEEK)[DAYS_OF_WEEK.MONDAY];
class DayOfWeekOfMonthSpec extends DimSpec{
    constructor(ordinalValue, dayOfWeek){
        super("DayOfWeekOfMonth");
        this.ordinalValue = ordinalValue || 1;
        this.dayOfWeek = dayOfWeek || MONDAY_KEY;
    }
}

export const FIRST_NTH_DAYS_OF_MONTH_ORDINALS = getOrdinalNumberMap(28);
export const LAST_NTH_DAYS_OF_MONTH_ORDINAL_MAP = {
    1: "Last Day",
    2: "2nd Last Day"
};

//there may be a 5th some day-of-week, but that may cause problems when calculating the next data in months where there is no 5th some day-of-week
export const FIRST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINALS = getOrdinalNumberMap(4);
export const LAST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINAL_MAP = {
    1: "Last",
    2: "2nd Last"
};



/*The following are the data structures for backend**/

export class BackendToDoRepeatStrategy {
    /**
     *
     * @param repeatMode  See {@link REPEAT_MODE}
     * @param modeDetail See {@link RepeatModeDetail}
     */
    constructor(repeatMode, modeDetail){
        this.repeatMode = repeatMode;
        this.modeDetail = modeDetail;
    }

    /**
     *
     * @param formModel See {@link ToDoRepeatStrategy}
     */
    static formModel2BackendModel(formModel){
        const backendModel = new BackendToDoRepeatStrategy();
        backendModel.repeatMode = formModel.repeatMode;

        switch(backendModel.repeatMode){
            case REPEAT_MODE.DAYS_OF_WEEK:
                backendModel.modeDetail = formModel.daysOfWeekModeDetail;
                break;
            case REPEAT_MODE.PERIOD:
                backendModel.modeDetail = formModel.periodModeDetail;
                break;
            case REPEAT_MODE.DAY_IN_MONTH:
                backendModel.modeDetail = BackendDayInMonthModeDetail.formModel2BackendModel(formModel.dayInMonthModeDetail);
                break;
            default:
                throw new Error("Unsupported repeat mode: " + backendModel.repeatMode);
        }
        return backendModel;
    }

    static backendModel2FormModel(backendModel){
        const formModel = new ToDoRepeatStrategy();
        formModel.repeatMode = backendModel.repeatMode;

        switch(formModel.repeatMode){
            case REPEAT_MODE.DAYS_OF_WEEK:
                formModel.daysOfWeekModeDetail = backendModel.modeDetail;
                break;
            case REPEAT_MODE.PERIOD:
                formModel.periodModeDetail = backendModel.modeDetail;
                break;
            case REPEAT_MODE.DAY_IN_MONTH:
                formModel.dayInMonthModeDetail = BackendDayInMonthModeDetail.backendModel2FormModel(backendModel.modeDetail);
                break;
            default:
                throw new Error("Unsupported repeat mode: " + formModel.repeatMode);
        }
        return formModel;
    }
}


class BackendDayInMonthModeDetail extends RepeatModeDetail{
    /**
     *
     * @param type  See {@link DAY_IN_MONTH_TYPE}
     * @param spec See {@link DimSpec}
     */
    constructor(type, spec){
        super("DayInMonth");
        this.type = type;
        this.spec = spec;
    }

    /**
     *
     * @param formModel  See {@link DayInMonthModeDetail}
     */
    static formModel2BackendModel(formModel){
        const backendModel = new BackendDayInMonthModeDetail();
        backendModel.type = formModel.type;
        switch(formModel.type){
            case DAY_IN_MONTH_TYPE.FIRST_NTH_DAY:
                backendModel.spec = formModel.firstNthDayOfMonthSpec;
                break;
            case DAY_IN_MONTH_TYPE.FIRST_NTH_DAY_OF_WEEK:
                backendModel.spec = formModel.firstNthDayOfWeekOfMonthSpec;
                break;
            case DAY_IN_MONTH_TYPE.LAST_NTH_DAY:
                backendModel.spec = formModel.lastNthDayOfMonthSpec;
                break;
            case DAY_IN_MONTH_TYPE.LAST_NTH_DAY_OF_WEEK:
                backendModel.spec = formModel.lastNthDayOfWeekOfMonthSpec;
                break;
            default:
                throw new Error("Unsupported type: " + formModel.type);
        }
        return backendModel;
    }


    static backendModel2FormModel(backendModel){
        const formModel = new DayInMonthModeDetail();
        formModel.type = backendModel.type;
        switch(backendModel.type){
            case DAY_IN_MONTH_TYPE.FIRST_NTH_DAY:
                formModel.firstNthDayOfMonthSpec = backendModel.spec;
                break;
            case DAY_IN_MONTH_TYPE.FIRST_NTH_DAY_OF_WEEK:
                formModel.firstNthDayOfWeekOfMonthSpec = backendModel.spec;
                break;
            case DAY_IN_MONTH_TYPE.LAST_NTH_DAY:
                formModel.lastNthDayOfMonthSpec = backendModel.spec;
                break;
            case DAY_IN_MONTH_TYPE.LAST_NTH_DAY_OF_WEEK:
                formModel.lastNthDayOfWeekOfMonthSpec = backendModel.spec;
                break;
            default:
                throw new Error("Unsupported type: " + backendModel.type);
        }
        return formModel;
    }
}