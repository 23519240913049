import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import LoadingAwareButton from "../../../uicomponents/LoadingAwareButton";
import {styles} from "../shared/AuthenticationStyles";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {hasValidToken} from "../shared/TokenReducers";
import PropTypes from "prop-types";
import {PATH_HOME, PATH_LOGIN} from "../../../global/FrontendUrls";
import Typography from "@material-ui/core/Typography/Typography";
import {NEW_PASSWORD_REGEX, NEW_PASSWORD_REGEX_TIP} from "../shared/AuthenticationConstants";
import {requestVerifyCodeAsyncAction, resetPasswordAsyncAction, validateVerifyCodeAsyncAction} from "./ForgetPasswordActions";


const VERIFY_CODE_NOT_GENERATED = "codeNotGenerated";
const VERIFY_CODE_NOT_VALIDATED = "codeNotValidated";
const PASSWORD_NOT_RESET = "passwordNotReset";
const PASSWORD_RESET = "passwordReset";

class ForgetPasswordComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                email: "",
                verifyCode: "",
                newPassword: "",
                repeatPassword: ""
            },

            processStatus: VERIFY_CODE_NOT_GENERATED
        }


        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.newPassword) {
                return false;
            }
            return true;
        });


        this.handleChange = this.handleChange.bind(this);
        this.handleCodeGenRequest = this.handleCodeGenRequest.bind(this);
        this.handleCodeValidationRequest = this.handleCodeValidationRequest.bind(this);
        this.handleResetPasswordRequest = this.handleResetPasswordRequest.bind(this);

        this.goToNotValidatedStatus = this.goToNotValidatedStatus.bind(this);
        this.goToPasswordNotResetStatus = this.goToPasswordNotResetStatus.bind(this);
        this.goToPasswordResetStatus = this.goToPasswordResetStatus.bind(this);
    }

    handleChange(event) {
        this.setState({
            formData: Object.assign({}, this.state.formData, {[event.target.name]: event.target.value.trim()})
        });
    }

    goToNotValidatedStatus() {
        this.setState({processStatus: VERIFY_CODE_NOT_VALIDATED});
    }

    goToPasswordNotResetStatus() {
        this.setState({processStatus: PASSWORD_NOT_RESET});
    }

    goToPasswordResetStatus() {
        this.setState({processStatus: PASSWORD_RESET});
    }

    handleCodeGenRequest() {
        this.props.dispatch(requestVerifyCodeAsyncAction(this.state.formData.email, this.goToNotValidatedStatus));
    }

    handleCodeValidationRequest() {
        let form = this.state.formData;
        this.props.dispatch(validateVerifyCodeAsyncAction(form.email, form.verifyCode, this.goToPasswordNotResetStatus));
    }

    handleResetPasswordRequest() {
        let form = this.state.formData;
        this.props.dispatch(resetPasswordAsyncAction(form.email, form.verifyCode, form.newPassword, 'Password reset success. Please login.', this.goToPasswordResetStatus));
    }

    render() {

        if (this.props.isLoggedIn) {
            return <Redirect to={{pathname: PATH_HOME}}/>
        }

        if (this.state.processStatus === PASSWORD_RESET){
            return <Redirect to={{pathname: PATH_LOGIN}}/>
        }

        switch (this.state.processStatus) {
            case VERIFY_CODE_NOT_GENERATED: {
                return (
                    <div className={this.props.classes.contentRoot}>

                        <ValidatorForm className={this.props.classes.form} onSubmit={this.handleCodeGenRequest}>

                            <Typography   variant="subheading" gutterBottom> Email </Typography>


                            <div className={this.props.classes.row}>
                                <TextValidator
                                    name="email" placeholder="Enter email to reset password" className={this.props.classes.textField}
                                    value={this.state.formData.email} onChange={this.handleChange}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['Email is required', 'Email is not valid']}
                                />
                            </div>

                            <div className={this.props.classes.row}>
                                <LoadingAwareButton type="submit" variant="contained" color="primary"
                                                    loading={this.props.loading}
                                                    className={this.props.classes.submitButton}>Send me Verification Code</LoadingAwareButton>
                            </div>

                        </ValidatorForm>

                    </div>

                );
            }

            case VERIFY_CODE_NOT_VALIDATED: {
                return (
                    <div className={this.props.classes.contentRoot}>

                        <ValidatorForm className={this.props.classes.form} onSubmit={this.handleCodeValidationRequest}>

                            <Typography   variant="subheading" gutterBottom> Verification Code </Typography>


                            <div className={this.props.classes.row}>
                                <TextValidator
                                    name="verifyCode" placeholder="The verification code you received"
                                    type="password" className={this.props.classes.textField}
                                    value={this.state.formData.verifyCode} onChange={this.handleChange}
                                    validators={['required']}
                                    errorMessages={['Verification code is required']}
                                />

                            </div>


                            <div className={this.props.classes.row}>
                                <LoadingAwareButton type="submit" variant="contained" color="primary"
                                                    loading={this.props.loading}
                                                    className={this.props.classes.submitButton}>Next</LoadingAwareButton>
                            </div>
                        </ValidatorForm>

                    </div>

                );
            }

            case PASSWORD_NOT_RESET: {
                return (
                    <div className={this.props.classes.contentRoot}>


                        <ValidatorForm className={this.props.classes.form} onSubmit={this.handleResetPasswordRequest}>



                            <Typography   variant="subheading" gutterBottom> Reset Password </Typography>


                            <div className={this.props.classes.row}>
                                <TextValidator
                                    name="newPassword" label="New Password" type="password" className={this.props.classes.textField}
                                    value={this.state.formData.newPassword} onChange={this.handleChange}
                                    validators={['required', NEW_PASSWORD_REGEX]}
                                    errorMessages={['New password is required', NEW_PASSWORD_REGEX_TIP]}
                                />
                            </div>

                            <div className={this.props.classes.row}>
                                <TextValidator
                                    name="repeatPassword" label="Repeat password" type="password"
                                    className={this.props.classes.textField}
                                    value={this.state.formData.repeatPassword} onChange={this.handleChange}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['Repeat password is required', 'Password mismatch']}
                                />
                            </div>


                            <div className={this.props.classes.row}>
                                <LoadingAwareButton type="submit" variant="contained" color="primary"
                                                    loading={this.props.loading}
                                                    className={this.props.classes.submitButton}>Submit</LoadingAwareButton>
                            </div>
                        </ValidatorForm>

                    </div>
                );
            }

            default:
                return (null);

        }


    }

}


ForgetPasswordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,

};
ForgetPasswordComponent = withStyles(styles)(ForgetPasswordComponent);


function mapStateToProps(state) {
    return {
        loading: state.global.loading,
        isLoggedIn: hasValidToken(state.token)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch: dispatch
    };
}

const ForgetPasswordComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgetPasswordComponent);

export default ForgetPasswordComponentContainer;
