export const PATH_HOME = "/";

export const PATH_LOGIN = "/auth/login";
export const PATH_RANDOM_CODE_LOGIN = "/auth/randomCodeLogin";
export const PATH_SIGN_UP = "/auth/signup";
export const PATH_FORGET_PASSWORD = "/auth/forgetPassword";

export const PATH_USER_PROFILE = "/user/profile";


export const PATH_TODO = "/todo";
export const PATH_TODO_ADD_ITEM = "/todo/items/new";

export const PATH_TODO_UPDATE_ITEM = "/todo/items/:itemId";
export const getToDoUpdateItemPath = (itemId) => `/todo/items/${itemId}`;