import * as React from "react";
import {TextField, withStyles} from "@material-ui/core";
import {toDoStyles} from "./ToDoStyles";
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {DAYS_OF_WEEK, PERIOD_UNIT} from "../../util/DateHelper";
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Field} from 'formik';
import {
    DAY_IN_MONTH_TYPE,
    FIRST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINALS,
    FIRST_NTH_DAYS_OF_MONTH_ORDINALS,
    LAST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINAL_MAP,
    LAST_NTH_DAYS_OF_MONTH_ORDINAL_MAP,
    REPEAT_MODE
} from "./model/ToDoRepeatStrategy";
import {isPositiveInteger} from "../../util/NumberHelper";
import FormHelperText from '@material-ui/core/FormHelperText';


export function validate(formValues, pathRoot) {
    let errors = {};
    if(formValues[pathRoot].repeatMode === REPEAT_MODE.DAYS_OF_WEEK
        &&  formValues[pathRoot].daysOfWeekModeDetail.daysOfWeek.length === 0){
        errors[`${pathRoot}.daysOfWeekModeDetail.daysOfWeek`] = 'Please choose at least one day-of-week';
    }
    return errors;
}


class ToDoRepeatStrategyEditComponent extends React.Component {

    constructor(props){
        super(props);

        this.handleChange_Generic = this.handleChange_Generic.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleChange_DaysOfWeekMode_DaysOfWeek = this.handleChange_DaysOfWeekMode_DaysOfWeek.bind(this);


        this.handleChange_PeriodModel_Amount = this.handleChange_PeriodModel_Amount.bind(this);
        this.handleChange_DayInMonthMode_LastNthDayOfMonth = this.handleChange_DayInMonthMode_LastNthDayOfMonth.bind(this);
        this.handleChange_DayInMonthMode_FirstNthDayOfMonth = this.handleChange_DayInMonthMode_FirstNthDayOfMonth.bind(this);

        this.handleChange_DayInMonthMode_FirstNthDayOfWeek_Ordinal = this.handleChange_DayInMonthMode_FirstNthDayOfWeek_Ordinal.bind(this);
        this.handleChange_DayInMonthMode_FirstNthDayOfWeek_DayOfWeek = this.handleChange_DayInMonthMode_FirstNthDayOfWeek_DayOfWeek.bind(this);


        this.handleChange_DayInMonthMode_LastNthDayOfWeek_Ordinal = this.handleChange_DayInMonthMode_LastNthDayOfWeek_Ordinal.bind(this);
        this.handleChange_DayInMonthMode_LastNthDayOfWeek_DayOfWeek = this.handleChange_DayInMonthMode_LastNthDayOfWeek_DayOfWeek.bind(this);

        this.handleChange_DayInMonthMode_Type = this.handleChange_DayInMonthMode_Type.bind(this);
    }


    handleModeChange(event) {
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.repeatMode', event.target.value );
    }

    handleChange_Generic(event, relativePath){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.' + relativePath, event.target.value );
    }

    handleChange_PeriodModel_Amount(event){
        const {pathRoot, setFieldValue} = this.props;
        let value = event.target.value;
        if(!isPositiveInteger(value)){
            value = 1;
        }
        setFieldValue(pathRoot + '.periodModeDetail.amount', value );
    }

    handleChange_DaysOfWeekMode_DaysOfWeek(event) {
        const {pathRoot, formValues, setFieldValue} = this.props;
        const daysOfWeek = new Set(formValues[pathRoot].daysOfWeekModeDetail.daysOfWeek);
        if (event.target.checked) {
            daysOfWeek.add(event.target.value);
        } else {
            daysOfWeek.delete(event.target.value);
        }
        setFieldValue(pathRoot + '.daysOfWeekModeDetail.daysOfWeek', [...daysOfWeek]);
    }


    handleChange_DayInMonthMode_Type(event) {
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.type', event.target.value);
    }

    /*day in month,  nth Day*/

    handleChange_DayInMonthMode_FirstNthDayOfMonth(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.firstNthDayOfMonthSpec.ordinalValue', event.target.value);
    }

    handleChange_DayInMonthMode_LastNthDayOfMonth(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.lastNthDayOfMonthSpec.ordinalValue', event.target.value);
    }


    /*day in month,  nth day of week*/
    handleChange_DayInMonthMode_FirstNthDayOfWeek_Ordinal(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.firstNthDayOfWeekOfMonthSpec.ordinalValue', event.target.value);
    }

    handleChange_DayInMonthMode_LastNthDayOfWeek_Ordinal(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.lastNthDayOfWeekOfMonthSpec.ordinalValue', event.target.value);
    }


    handleChange_DayInMonthMode_FirstNthDayOfWeek_DayOfWeek(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.firstNthDayOfWeekOfMonthSpec.dayOfWeek', event.target.value);
    }

    handleChange_DayInMonthMode_LastNthDayOfWeek_DayOfWeek(event){
        const {pathRoot, setFieldValue} = this.props;
        setFieldValue(pathRoot + '.dayInMonthModeDetail.lastNthDayOfWeekOfMonthSpec.dayOfWeek', event.target.value);
    }


    render() {
        const {classes, pathRoot, formValues, formErrors} = this.props;

        return (
            <div className={classes.repeatStrategyRoot}>
                {/*<div className={classes.compactRow}>*/}

                    {/*<TextField label="Start Date" value={dateToString(this.props.startDate)} InputProps={{readOnly: true}}/>*/}

                    {/*<div className={this.props.classes.tenPercentSpace}/>*/}

                    {/*<Field component={InlineDatePicker} onlyCalendar={true} label="End Date"*/}
                                      {/*value={formValues[pathRoot].endDate || null} format={NEUTRAL_DATE_FORMAT}*/}
                                      {/*onChange={(newDate) => setFieldValue(`${pathRoot}.endDate`, newDate)}*/}
                    {/*/>*/}

                {/*</div>*/}

                <div className={classes.compactRow}>
                    <Field component={FormControlLabel} value={REPEAT_MODE.PERIOD}
                           checked={formValues[pathRoot].repeatMode === REPEAT_MODE.PERIOD}
                           control={<Radio onChange={this.handleModeChange}/> } label="Every" />

                    <Field component={TextField}
                           value={formValues[pathRoot].periodModeDetail.amount}
                           onChange={(event) => this.handleChange_PeriodModel_Amount(event, "periodModeDetail.amount")}
                           classes={{root: classes.periodAmount}}
                           type="number"
                           inputProps={{ min: "1"}}
                    />

                    <div className={this.props.classes.fivePercentSpace}/>

                    <Field component={TextField}
                           select
                           value={formValues[pathRoot].periodModeDetail.unit}
                           onChange={(event) => this.handleChange_Generic(event, "periodModeDetail.unit")}
                           classes={{root: classes.periodUnit}}
                    >
                        {Object.keys(PERIOD_UNIT).map(key => (
                            <MenuItem key={key} value={key}>
                                {PERIOD_UNIT[key]}
                            </MenuItem>
                        ))}
                    </Field>
                </div>

                <div className={classes.compactRow}>
                    <Field component={FormControlLabel} value={REPEAT_MODE.DAYS_OF_WEEK}
                           checked={formValues[pathRoot].repeatMode === REPEAT_MODE.DAYS_OF_WEEK}
                           control={<Radio onChange={this.handleModeChange}/> } label="On Days of Weeks" />

                </div>

                {formValues[pathRoot].repeatMode === REPEAT_MODE.DAYS_OF_WEEK &&
                <React.Fragment>

                    <div className={classes.indentBelowRepeatModel}>

                        {Object.keys(DAYS_OF_WEEK).map(key => (
                            <FormControlLabel key={key}
                                              control={
                                                  <Field component={Checkbox}
                                                      checked={formValues[pathRoot].daysOfWeekModeDetail.daysOfWeek.includes(key)}
                                                      onChange={this.handleChange_DaysOfWeekMode_DaysOfWeek}
                                                      value={key}
                                                  />
                                              }
                                              label={DAYS_OF_WEEK[key]}
                            />
                        ))}

                    </div>

                    <div className={classes.indentBelowRepeatModel}>
                        {formErrors[`${pathRoot}.daysOfWeekModeDetail.daysOfWeek`] &&
                        <FormHelperText error={true}>{formErrors[`${pathRoot}.daysOfWeekModeDetail.daysOfWeek`]}</FormHelperText>
                        }
                    </div>

                </React.Fragment>
                }

                <div className={classes.compactRow}>
                    <Field component={FormControlLabel} value={REPEAT_MODE.DAY_IN_MONTH}
                           checked={formValues[pathRoot].repeatMode === REPEAT_MODE.DAY_IN_MONTH}
                           control={<Radio onChange={this.handleModeChange}/> } label="On Someday in Month" />

                </div>
                {formValues[pathRoot].repeatMode === REPEAT_MODE.DAY_IN_MONTH &&
                <React.Fragment>

                    {/*First nth Day in a month*/}

                    <div className={classes.indentBelowRepeatModel}>
                        <Field component={Radio} value={DAY_IN_MONTH_TYPE.FIRST_NTH_DAY}
                                checked={formValues[pathRoot].dayInMonthModeDetail.type === DAY_IN_MONTH_TYPE.FIRST_NTH_DAY}
                                onChange={this.handleChange_DayInMonthMode_Type}
                        />
                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.firstNthDayOfMonthSpec.ordinalValue}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">The </InputAdornment>,
                                endAdornment: <InputAdornment position="end" classes={{root: classes.endAdornmentUnderMonthlyMode}}> of Every Month</InputAdornment>,
                            }}
                            SelectProps={{
                                classes: {root: classes.lastSelectUnderMonthlyMode}
                            }}
                            classes={{root: classes.nThDayOfMonthField}}
                            onChange={this.handleChange_DayInMonthMode_FirstNthDayOfMonth}
                        >
                            {Object.keys(FIRST_NTH_DAYS_OF_MONTH_ORDINALS).map(key => (
                                <MenuItem key={key} value={key}>
                                    {FIRST_NTH_DAYS_OF_MONTH_ORDINALS[key]} Day
                                </MenuItem>
                            ))}
                        </Field>
                    </div>


                    {/*Last nth Day in a month*/}
                    <div className={classes.indentBelowRepeatModel}>
                        <Field component={Radio} value={DAY_IN_MONTH_TYPE.LAST_NTH_DAY}
                               checked={formValues[pathRoot].dayInMonthModeDetail.type === DAY_IN_MONTH_TYPE.LAST_NTH_DAY}
                               onChange={this.handleChange_DayInMonthMode_Type}
                        />
                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.lastNthDayOfMonthSpec.ordinalValue}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">The </InputAdornment>,
                                endAdornment: <InputAdornment position="end" classes={{root: classes.endAdornmentUnderMonthlyMode}}> of Every Month</InputAdornment>,
                            }}
                            SelectProps={{
                                classes: {root: classes.lastSelectUnderMonthlyMode}
                            }}
                            classes={{root: classes.nThDayOfMonthField}}
                            onChange={this.handleChange_DayInMonthMode_LastNthDayOfMonth}
                        >
                            {Object.keys(LAST_NTH_DAYS_OF_MONTH_ORDINAL_MAP).map(key => (
                                <MenuItem key={key} value={key}>
                                    {LAST_NTH_DAYS_OF_MONTH_ORDINAL_MAP[key]}
                                </MenuItem>
                            ))}
                        </Field>
                    </div>




                    {/*First nth Day of week in a month*/}

                    <div className={classes.indentBelowRepeatModel}>
                        <Field component={Radio} value={DAY_IN_MONTH_TYPE.FIRST_NTH_DAY_OF_WEEK}
                               checked={formValues[pathRoot].dayInMonthModeDetail.type === DAY_IN_MONTH_TYPE.FIRST_NTH_DAY_OF_WEEK}
                               onChange={this.handleChange_DayInMonthMode_Type}
                        />
                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.firstNthDayOfWeekOfMonthSpec.ordinalValue}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">The </InputAdornment>,
                            }}
                            SelectProps={{
                                   classes: {root: classes.nonLastSelectUnderMonthlyMode}
                            }}

                            onChange={this.handleChange_DayInMonthMode_FirstNthDayOfWeek_Ordinal}
                        >
                            {Object.keys(FIRST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINALS).map(key => (
                                <MenuItem key={key} value={key}>
                                    {FIRST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINALS[key]}
                                </MenuItem>
                            ))}
                        </Field>

                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.firstNthDayOfWeekOfMonthSpec.dayOfWeek}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" classes={{root: classes.endAdornmentUnderMonthlyMode}}> of Every Month</InputAdornment>,
                            }}
                            SelectProps={{
                                classes: {root: classes.lastSelectUnderMonthlyMode}
                            }}
                            onChange={this.handleChange_DayInMonthMode_FirstNthDayOfWeek_DayOfWeek}
                            classes={{root: classes.nThDayOfMonthField}}
                        >
                            {Object.keys(DAYS_OF_WEEK).map(key => (
                                <MenuItem key={key} value={key}>
                                    {DAYS_OF_WEEK[key]}
                                </MenuItem>
                            ))}
                        </Field>
                    </div>

                    {/*Last nth Day of week in a month*/}

                    <div className={classes.indentBelowRepeatModel}>
                        <Field component={Radio} value={DAY_IN_MONTH_TYPE.LAST_NTH_DAY_OF_WEEK}
                               checked={formValues[pathRoot].dayInMonthModeDetail.type === DAY_IN_MONTH_TYPE.LAST_NTH_DAY_OF_WEEK}
                               onChange={this.handleChange_DayInMonthMode_Type}
                        />
                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.lastNthDayOfWeekOfMonthSpec.ordinalValue}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">The </InputAdornment>,
                            }}
                            SelectProps={{
                                   classes: {root: classes.nonLastSelectUnderMonthlyMode}
                            }}
                            onChange={this.handleChange_DayInMonthMode_LastNthDayOfWeek_Ordinal}
                        >
                            {Object.keys(LAST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINAL_MAP).map(key => (
                                <MenuItem key={key} value={key}>
                                    {LAST_NTH_DAY_OF_WEEK_OF_MONTH_ORDINAL_MAP[key]}
                                </MenuItem>
                            ))}
                        </Field>

                        <Field component={TextField}
                            select
                            value={formValues[pathRoot].dayInMonthModeDetail.lastNthDayOfWeekOfMonthSpec.dayOfWeek}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" classes={{root: classes.endAdornmentUnderMonthlyMode}}> of Every Month</InputAdornment>,
                            }}
                            SelectProps={{
                                classes: {root: classes.lastSelectUnderMonthlyMode}
                            }}
                            onChange={this.handleChange_DayInMonthMode_LastNthDayOfWeek_DayOfWeek}
                            classes={{root: classes.nThDayOfMonthField}}
                        >
                            {Object.keys(DAYS_OF_WEEK).map(key => (
                                <MenuItem key={key} value={key}>
                                    {DAYS_OF_WEEK[key]}
                                </MenuItem>
                            ))}
                        </Field>
                    </div>

                </React.Fragment>
                }

            </div>
        );
    }
}


ToDoRepeatStrategyEditComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,  //moment.js date
    pathRoot: PropTypes.string.isRequired, // the path root used to build an absolute path of fields in parent form
    formValues: PropTypes.object.isRequired, //formik stuff
    formErrors: PropTypes.object.isRequired, //formik stuff
    formTouched: PropTypes.object.isRequired, //formik stuff
    setFieldValue: PropTypes.func.isRequired //formik stuff
};

ToDoRepeatStrategyEditComponent = withStyles(toDoStyles)(ToDoRepeatStrategyEditComponent);

export default ToDoRepeatStrategyEditComponent;