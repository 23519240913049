// actions
import {loadingStarted} from "../../../global/GlobalActions";
import axios from "axios";
import {GET_MY_PROFILE} from "../../../global/BackendUrls";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";
import {getAccessToken} from "../../auth/shared/TokenReducers";

//actions
export const SET_PROFILE_FROM_BACKEND_RESPONSE = "setProfileFromBackendResponse";
export const CLEAR_PROFILE_LOCALLY = "clearProfileLocally";

//action handlers
export function buildSetProfileFromBackendResponseAction(data) {
    return {type: SET_PROFILE_FROM_BACKEND_RESPONSE, responseData: data};
}

export function cleanProfileLocallyAction(){
    return {type: CLEAR_PROFILE_LOCALLY};
}


//async actions
export function getMyProfileAsyncAction(token) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.get(
            GET_MY_PROFILE,

            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(buildSetProfileFromBackendResponseAction(response.data));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, null))
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}