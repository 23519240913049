import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import connect from "react-redux/es/connect/connect";
import ToDoItemComponent from "./ToDoItemComponent";
import {toDoStyles} from './ToDoStyles'
import Divider from "@material-ui/core/es/Divider/Divider";
import classNames from 'classnames';
import {changeCurrentDateFilterAction, finishToDoItemAsyncAction, getMyToDoItemsAsyncAction} from "./ToDoActions";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {PATH_TODO_ADD_ITEM} from "../../global/FrontendUrls";
import {Link} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import {DATE_FILTERS} from "./ToDoConstants";
import {filterItems} from "./ToDoReducers";
import {dateToString, getClientCurrentDate} from "./ToDoUtils";


function toDoListStyles(theme) {
    const moduleStyles = toDoStyles(theme);
    let doToListRootStyle = {
        ...moduleStyles.contentRoot,
        padding: 0
    };

    return {
        ...moduleStyles,
        toDoListContentRoot: doToListRootStyle
    }
}


class ToDoListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.onItemFinish = this.onItemFinish.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }


    onItemFinish(item) {
        this.props.dispatch(finishToDoItemAsyncAction(this.props.token, item.id));
    }

    handleTabChange(event, value) {
        this.props.dispatch(changeCurrentDateFilterAction(value));
    }


    render() {
        const {classes} = this.props;
        const toDoItems = this.props.itemList;

        let pathToAddItem = PATH_TODO_ADD_ITEM;
        if (this.props.currentDateFilter === DATE_FILTERS.TODAY) {
            pathToAddItem += "?date=" + dateToString(getClientCurrentDate());
        }

        if (this.props.currentDateFilter === DATE_FILTERS.TOMORROW) {
            pathToAddItem += "?date=" + dateToString(getClientCurrentDate().add('days', 1));
        }

        return (
            <div className={classes.toDoListContentRoot}>


                {(toDoItems.length > 0) &&

                <React.Fragment>

                    <AppBar position="static" color="default">
                        <Tabs indicatorColor="primary"
                              textColor="primary"
                              onChange={this.handleTabChange}
                              value={this.props.currentDateFilter}
                              fullWidth
                        >
                            <Tab value={DATE_FILTERS.TODAY} label={DATE_FILTERS.TODAY}/>
                            <Tab value={DATE_FILTERS.TOMORROW} label={DATE_FILTERS.TOMORROW}/>
                            <Tab value={DATE_FILTERS.FUTURE} label={DATE_FILTERS.FUTURE}/>
                        </Tabs>
                    </AppBar>


                    <List className={classNames(classes.row)}>
                        {filterItems(toDoItems, this.props.currentDateFilter).map((item, index) => (
                            <React.Fragment key={item.id}>
                                {index > 0 &&
                                <Divider className={classes.rowInsideList}/>
                                }
                                <ToDoItemComponent item={item} onItemFinish={this.onItemFinish} currentDateFilter={this.props.currentDateFilter}/>
                            </React.Fragment>
                        ))}
                    </List>


                </React.Fragment>
                }

                {(toDoItems.length === 0) &&
                <div className={classNames(classes.row)}>
                    <div className={classes.nothingToDoMsg}>Nothing to do yet</div>
                </div>
                }


                <Fab component={Link} className={classes.newItemBtn} color="primary" to={pathToAddItem}>
                    <AddIcon/>
                </Fab>

            </div>
        );


    }


}

ToDoListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    itemList: PropTypes.array.isRequired,
    token: PropTypes.object.isRequired,
    currentDateFilter: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
};

ToDoListComponent = withStyles(toDoListStyles)(ToDoListComponent);


class ToDoListComponentContainer extends React.Component {
    componentDidMount() {
        if (this.props.itemList == null) { //null or undefined
            this.props.dispatch(getMyToDoItemsAsyncAction(this.props.token));
        }
    }

    render() {
        if (this.props.itemList) {
            return <ToDoListComponent {...this.props}/>;
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        itemList: state.toDo.itemList,
        currentDateFilter: state.toDo.currentDateFilter,
        token: state.token
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch: dispatch}
}


ToDoListComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ToDoListComponentContainer);

export default ToDoListComponentContainer;