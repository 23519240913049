import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {errorColor, infoColor, successColor, warningColor} from "./UiConstants";
import classNames from 'classnames';

const styles = (theme) => ({

    bar: {},

    barContent: {
        flexWrap: "nowrap",
        maxWidth: "100%"
    },

    message: {
        width: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },

    success: {
        backgroundColor: successColor(theme),
    },
    error: {
        backgroundColor: errorColor(theme),
    },
    info: {
        backgroundColor: infoColor(theme),
    },
    warning: {
        backgroundColor: warningColor(theme),
    }

});


class MessageBar extends React.Component {

    render() {

        if (!this.props.message || !this.props.message.trim()) {
            return (null);
        }


        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                message={this.props.message}
                autoHideDuration={3000}
                onClose={this.props.onClose}
                classes={{
                    root: this.props.classes.bar
                }}

                ContentProps={{
                    classes: {
                        root: classNames(this.props.classes[this.props.messageLevel], this.props.classes.barContent),
                        message: this.props.classes.message
                    }
                }}

                action={(

                    <IconButton
                        color="inherit"
                        onClick={this.props.onClose}
                    >
                        <CloseIcon/>
                    </IconButton>

                )}

            />
        )
    }
}

MessageBar.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string,
    messageLevel: PropTypes.string,
    onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(MessageBar);
