import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import CommonLink from '../../../uicomponents/CommonLink';
import Typography from '@material-ui/core/Typography';
import LoadingAwareButton from "../../../uicomponents/LoadingAwareButton";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {styles} from "../shared/AuthenticationStyles";
import {SocialLoginFragmentContainer as SocialLoginFragment} from "../shared/SocialLoginFragment";
import {connect} from "react-redux";
import {hasValidToken} from "../shared/TokenReducers";
import {loginAsyncAction} from "./LoginActions";
import classNames from 'classnames';
import {PATH_FORGET_PASSWORD, PATH_HOME, PATH_SIGN_UP} from "../../../global/FrontendUrls";

class LoginComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                email: "",
                password: ""
            }
        }
        this.handleChange = this.handleChange.bind(this);


    }

    handleChange(event) {
        this.setState({
            formData: Object.assign({}, this.state.formData, {[event.target.name]: event.target.value.trim()})
        });
    }


    render() {

        const {from} = this.props.location.state || {from: {pathname: PATH_HOME}};

        if (this.props.isLoggedIn) {
            return <Redirect to={from}/>
        }

        return (

            <div className={this.props.classes.contentRoot}>

                <ValidatorForm className={this.props.classes.form}
                               onSubmit={e => this.props.onSubmit(this.state.formData.email, this.state.formData.password)}>

                    <SocialLoginFragment classes={this.props.classes}/>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="email" label="Email" className={this.props.classes.textField}
                            value={this.state.formData.email} onChange={this.handleChange}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                        />
                    </div>

                    <div className={this.props.classes.row}>
                        <TextValidator
                            name="password" label="Password" type="password" className={this.props.classes.textField}
                            value={this.state.formData.password} onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['Password is required']}
                        />

                    </div>

                    <div className={this.props.classes.row}>
                        <div className={this.props.classes.takeUpSpace}/>
                        <div>
                            <CommonLink className={this.props.classes.forgotPasswordLink} to={PATH_FORGET_PASSWORD}>Forgot
                                Password?</CommonLink>
                        </div>
                    </div>

                    <div className={this.props.classes.row}>
                        <LoadingAwareButton type="submit" variant="contained" color="primary"
                                            loading={this.props.loading}
                                            className={this.props.classes.submitButton}>Log in</LoadingAwareButton>
                    </div>


                    <div className={classNames(this.props.classes.row, this.props.classes.loginOrSignUpSwitchPrompt)}>
                        <Typography gutterBottom noWrap align="center">
                            No account yet? <CommonLink to={{pathname: PATH_SIGN_UP, state: {from: from}}}>Sign up</CommonLink>
                        </Typography>

                    </div>

                    <div className={this.props.classes.row}>
                        <Typography gutterBottom noWrap>
                             <CommonLink to="/privacy-policy.html" target="_blank">Privacy Policy</CommonLink>
                        </Typography>
                    </div>



                </ValidatorForm>
            </div>
        );
    }
}

LoginComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};
LoginComponent = withStyles(styles)(LoginComponent);


function mapStateToProps(state) {
    return {
        loading: state.global.loading, //required for LoadingAwareButton
        isLoggedIn: hasValidToken(state.token)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmit: (email, password) => dispatch(loginAsyncAction(email, password))
    };
}


export const LoginComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent);