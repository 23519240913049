import * as moment from 'moment';

// actions
export const SET_TOKEN = "setToken";
export const CLEAR_TOKEN = "clearToken";

export function setTokenAction(token) {
    return {type: SET_TOKEN, token: token};
}

export function clearTokenAction() {
    return {type: CLEAR_TOKEN};
}

//other functions
export const newTokenFromBackendResponse = function (response) {
    let expiresMilli = moment().valueOf() + response.data.expires_in * 1000;
    return {
        access_token: response.data.access_token,
        expires_at_str: moment(expiresMilli).format(),
        user_principal: response.data.user_principal
    }
}