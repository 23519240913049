import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider/Divider";
import {connect} from "react-redux";
import {cleanProfileLocallyAction, getMyProfileAsyncAction} from "./UserProfileActions";
import PropTypes from "prop-types";
import EmailComponent from "../email/EmailComponent"
import ChangePasswordComponent from "../change-password/ChangePasswordComponent"
import {SOURCE_LOCAL} from "../../../global/BizConstants";
import {hasUserProfile} from "./UserProfileReducers";
import sharedStyles from "../../../global/SharedStyles";

const styles = theme => ({

    ...sharedStyles(theme),

    rootStack: {
        display: "flex",
        [theme.breakpoints.up('xs')]: {
            width: "100%"
        },

        [theme.breakpoints.up('lg')]: {
            width: "80%",

        },
        flexDirection: "column",
        padding: theme.spacing.unit * 3
    },



    divider: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1
    }
});

class UserProfileComponent extends React.Component {

    render() {

        return (

            <div className={this.props.classes.rootStack}>

                <EmailComponent/>

                {this.props.userProfile.source === SOURCE_LOCAL &&
                <React.Fragment>
                    <Divider className={this.props.classes.divider}/>
                    <ChangePasswordComponent/>
                </React.Fragment>
                }

            </div>
        );


    }
}


UserProfileComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
};

UserProfileComponent = withStyles(styles)(UserProfileComponent);

class UserProfileComponentContainer extends React.Component {
    componentDidMount() {
        this.props.dispatch(getMyProfileAsyncAction(this.props.rootState.token));
    }
    componentWillUnmount(){
        this.props.dispatch(cleanProfileLocallyAction());
    }
    render() {
        if (hasUserProfile(this.props.rootState)) {
            return <UserProfileComponent userProfile={this.props.rootState.userProfile}/>;
        } else {
            return null;
        }
    }
}

UserProfileComponentContainer = connect(
    state => ({rootState: state}),
    dispatch => ({dispatch: dispatch})
)(UserProfileComponentContainer);


export default UserProfileComponentContainer;