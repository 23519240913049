import {CHANGE_CURRENT_DATE_FILTER, CLEAR_CURRENT_TO_DO_ITEM_LOCALLY, CURRENT_DO_ITEM_LOADED, TO_DO_ITEM_FINISHED, TO_DO_ITEMS_LOADED} from './ToDoActions'
import {backendDateToMoment, getClientCurrentDate} from "./ToDoUtils";
import {DATE_FILTERS, ITEM_TYPE} from "./ToDoConstants";
import {BackendToDoRepeatStrategy} from "./model/ToDoRepeatStrategy";

/**
 * the prototype
 * @param id required
 * @param task required
 * @param moreInfo optional
 * @param date  moment.js type
 * @param repeatStrategy optional  See {@link ToDoRepeatStrategy}
 * @constructor
 */
//
export function ToDoItem(id, type, task, moreInfo, date, nextDate, repeatStrategy) {
    this.id = id;
    this.type = type;
    this.task = task;
    this.moreInfo = moreInfo;
    this.date = date;
    this.nextDate = nextDate;
    this.repeatStrategy = repeatStrategy;
}

const initialState = {
    itemList: null,    //null or undefined means it's not been loaded yet, empty list means there indeed is no any item
    currentItem: null,
    currentDateFilter: DATE_FILTERS.TODAY
};

function buildItemFromBackendItem(backendItem) {
    return new ToDoItem(backendItem.id, backendItem.type, backendItem.task,
        backendItem.moreInfo, backendDateToMoment(backendItem.date), backendDateToMoment(backendItem.nextDate),
        backendItem.repeatStrategy ? BackendToDoRepeatStrategy.backendModel2FormModel(backendItem.repeatStrategy): null
    );
}

//selectors
export function filterItems(itemList, currentDateFilter) {
    if (itemList == null) {
        return null;
    }
    let clientCurrentDate = getClientCurrentDate();
    switch (currentDateFilter) {
        case DATE_FILTERS.TODAY:
            return itemList.filter(i =>
                ((i.type === ITEM_TYPE.INDEPENDENT || i.type === ITEM_TYPE.SERIAL_INSTANCE)
                    &&  i.date && i.date.diff(clientCurrentDate, 'days') <= 0));
        case DATE_FILTERS.TOMORROW:
            return itemList.filter(i =>
                ((i.type === ITEM_TYPE.INDEPENDENT || i.type === ITEM_TYPE.SERIAL_INSTANCE) &&
                i.date && i.date.diff(clientCurrentDate, 'days') === 1));
        case DATE_FILTERS.FUTURE:
            return itemList.filter(i => (
                    ((i.type === ITEM_TYPE.INDEPENDENT || i.type === ITEM_TYPE.SERIAL_INSTANCE)  && ((i.date == null) || (i.date.diff(clientCurrentDate, 'days') > 1)))
                    ||
                    (i.type === ITEM_TYPE.SERIAL)
                ));

        default:
            return itemList;  //shouldn't reach this, but yeah, whatever
    }
}

export function toDoReducer(state = initialState, action) {
    switch (action.type) {

        case TO_DO_ITEM_FINISHED:
            return {
                ...state,
                // eslint-disable-next-line
                itemList: state.itemList.filter(item => item.id != action.id)
            };

        case TO_DO_ITEMS_LOADED:
            return {
                ...state,
                itemList: action.responseData.map(backendItem => buildItemFromBackendItem(backendItem))
            }

        case CURRENT_DO_ITEM_LOADED:
            let backendItem = action.responseData;
            return {
                ...state,
                currentItem: buildItemFromBackendItem(backendItem)
            }

        case CLEAR_CURRENT_TO_DO_ITEM_LOCALLY:
            return {
                ...state,
                currentItem: null
            }

        case CHANGE_CURRENT_DATE_FILTER:
            return {
                ...state,
                currentDateFilter: action.filter
            }

        default:
            return state;
    }
}

