import React from 'react';
import sharedStyles from "../../global/SharedStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    ...sharedStyles(theme)
});


class NoPageFoundComponent extends React.Component {
    render() {
        return (<div className={this.props.classes.contentRoot}><h3>No page found</h3></div>);
    }
}

NoPageFoundComponent = withStyles(styles)(NoPageFoundComponent);

export {NoPageFoundComponent};