// value: text
export const DAYS_OF_WEEK = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
}


// value: text
export const PERIOD_UNIT = {
    DAY: "Day(s)",
    WEEK: "Week(s)",
    MONTH: "Month(s)",
    YEAR: "Year(s)"
}

