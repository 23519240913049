//actions

import {loadingStarted} from "../../global/GlobalActions";
import axios from "axios";
import {GET_MY_TODO_ITEMS, getFinishToDoItemUrl, getSingleToDoItemUrl, getUpdateToDoItemUrl, NEW_TODO_ITEM} from "../../global/BackendUrls";
import {getAccessToken} from "../auth/shared/TokenReducers";
import {handleFetchError, handleFetchSuccess} from "../../global/GlobalHandlers";
import {NEUTRAL_DATE_FORMAT, NEUTRAL_DATETIME_FORMAT} from "./ToDoConstants";
import {CLIENT_CURRENT_DATETIME_HEADER_KEY} from "../../global/BizConstants";
import moment from "moment";


export const TO_DO_ITEMS_LOADED = "toDoItemsLoaded";
export const CURRENT_DO_ITEM_LOADED = "currentToDoItemLoaded";
export const TO_DO_ITEM_FINISHED = "toDoItemFinished";
export const CLEAR_TO_DO_ITEMS_LOCALLY = "clearToDoItemsLocally";
export const CLEAR_CURRENT_TO_DO_ITEM_LOCALLY = "clearCurrentToDoItemLocally";

export const CHANGE_CURRENT_DATE_FILTER = "changeCurrentDateFilter";

//action creators

function toDoItemFinishedAction(id){
    return {type:TO_DO_ITEM_FINISHED, id:id};
}

function toDoItemsLoadedAction(data) {
    return {type: TO_DO_ITEMS_LOADED, responseData: data};
}

function currentToDoItemLoadedAction(data) {
    return {type: CURRENT_DO_ITEM_LOADED, responseData: data};
}

export function clearToDoItemsLocallyAction(){
    return {type: CLEAR_TO_DO_ITEMS_LOCALLY}
}

export function clearCurrentToDoItemLocallyAction(){
    return {type: CLEAR_CURRENT_TO_DO_ITEM_LOCALLY}
}

export function changeCurrentDateFilterAction(filter){
    return {type: CHANGE_CURRENT_DATE_FILTER,  filter: filter}
}

//async actions
export function getMyToDoItemsAsyncAction(token, userInfo) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.get(
            GET_MY_TODO_ITEMS,

            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        [CLIENT_CURRENT_DATETIME_HEADER_KEY]: moment().format(NEUTRAL_DATETIME_FORMAT),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(toDoItemsLoadedAction(response.data));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, userInfo))
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}


export function getCurrentToDoItemAsyncAction(token, itemId) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.get(
            getSingleToDoItemUrl(itemId),

            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        [CLIENT_CURRENT_DATETIME_HEADER_KEY]: moment().format(NEUTRAL_DATETIME_FORMAT),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(currentToDoItemLoadedAction(response.data));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch))
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}


export function finishToDoItemAsyncAction(token, itemId) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            getFinishToDoItemUrl(itemId),
            null,
            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        [CLIENT_CURRENT_DATETIME_HEADER_KEY]: moment().format(NEUTRAL_DATETIME_FORMAT),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(toDoItemFinishedAction(itemId));
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, "Item finished"))
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}

/**
 *
 * @param date  type is moment.js date
 * @param repeatStrategy See {@link BackendToDoRepeatStrategy}
 *
 */
export function newToDoItemAsyncAction(token, task, moreInfo, date, repeatStrategy, successCallback) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            NEW_TODO_ITEM,
            JSON.stringify({
                task: task,
                moreInfo: moreInfo,
                date: date == null? null: date.format(NEUTRAL_DATE_FORMAT),
                repeatStrategy: repeatStrategy
            }),
            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        [CLIENT_CURRENT_DATETIME_HEADER_KEY]: moment().format(NEUTRAL_DATETIME_FORMAT),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(getMyToDoItemsAsyncAction(token, "Item created"));
            return response;
        }).then(successCallback)
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}


/**
 *
 * @param date  type is moment.js date
 * @param repeatStrategy See {@link BackendToDoRepeatStrategy}
 *
 */
export function updateToDoItemAsyncAction(token, itemId, task, moreInfo, date, repeatStrategy, successCallback) {

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            getUpdateToDoItemUrl(itemId),
            JSON.stringify({
                id: itemId,
                task: task,
                moreInfo: moreInfo,
                date: date == null? null: date.format(NEUTRAL_DATE_FORMAT),
                repeatStrategy: repeatStrategy
            }),
            {
                headers:
                    {
                        'Authorization': "Bearer " + getAccessToken(token),
                        [CLIENT_CURRENT_DATETIME_HEADER_KEY]: moment().format(NEUTRAL_DATETIME_FORMAT),
                        'Content-Type': 'application/json'
                    }
            }
        );

        promise.then(function (response) {
            dispatch(getMyToDoItemsAsyncAction(token, "Item updated"));
            return response;
        }).then(successCallback)
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}

