import sharedStyles from "../../global/SharedStyles";

const fontSizeForSelectUnderMonthlyModeIfMobile = "90%";
export const toDoStyles = theme => ({
    ...sharedStyles(theme),

    takeUpSpace: {
        flexGrow: 1
    },

    tenPercentSpace: {
        flexGrow: 0.1
    },

    fivePercentSpace: {
        flexGrow: 0.05
    },


    row: {
        flexBasis: "100%", //as an item of parent flex container

        display: "flex", //as a flex container itself
        flexWrap: "wrap",

        marginTop: theme.spacing.unit * 2,
    },


    compactRow: {
        flexBasis: "100%", //as an item of parent flex container

        display: "flex", //as a flex container itself
        flexWrap: "wrap"
    },

    rowInsideList: {
        wordWrap: "break-word",
        flexBasis: "100%", //as an item of parent flex container

        display: "flex", //as a flex container itself
        flexWrap: "wrap"

    },


    title: {
        textAlign: "center"
    },

    nothingToDoMsg: {
        textAlign: "center",
        flexBasis: "100%"
    },

    newItemBtn: {
        position: 'absolute',
        bottom: theme.spacing.unit * 10,
        right: theme.spacing.unit * 2,
    },

    textField: {
        width: "100%",
    },


    formButton: {
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            width: "20%",
            marginRight: "10%"
        },
        marginBottom: theme.spacing.unit * 2
    },

    editItemFormSaveButton: {
        marginTop: theme.spacing.unit * 2,
    },

    editItemFormCancelButton: {
        marginTop: theme.spacing.unit * 2,
    },


    /*repeat strategy styles begin*/
    repeatStrategyRoot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        paddingLeft: theme.spacing.unit * 3
    },

    indentBelowRepeatModel: {
        paddingLeft: theme.spacing.unit * 3,
        [theme.breakpoints.down('xs')]:{
          paddingLeft: theme.spacing.unit * 1,
        },
        flexBasis: "100%", //as an item of parent flex container
        display: "flex", //as a flex container itself
        flexWrap: "wrap",
    },

    periodAmount: {
        width: "10%"
    },

    periodUnit: {},


    nonLastSelectUnderMonthlyMode: {
        [theme.breakpoints.down('xs')]: {
            fontSize: fontSizeForSelectUnderMonthlyModeIfMobile
        }
    },

    lastSelectUnderMonthlyMode: {
        width: "30%",  //leave some space for the ending adornment
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
            fontSize: fontSizeForSelectUnderMonthlyModeIfMobile
        }
    },

    endAdornmentUnderMonthlyMode: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },

    selectUnderMonthlyModeHalfWidth: {
        // width: "15%"
    },

    nThDayOfMonthField: {
        flexGrow: 1
    },

    nThDayOfMonthFieldHalfWidth: {
        flexGrow: 0.5
    }


    /*repeat strategy styles end*/

});

