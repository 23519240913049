const BACKEND_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

export const LOGOUT = `${BACKEND_URL_BASE}/token/delete`;

export const SIGN_UP = `${BACKEND_URL_BASE}/token/new/by-register/local	`;
export const LOGIN = `${BACKEND_URL_BASE}/token/new/local`;
export const GOOGLE_LOGIN = `${BACKEND_URL_BASE}/token/new/social/by-auth-code/google/web`;
export const FACEBOOK_LOGIN = `${BACKEND_URL_BASE}/token/new/social/by-token/facebook/web`;

export const RANDOM_CODE_LOGIN = `${BACKEND_URL_BASE}/token/new/by-random-code/local`;
export const GEN_RANDOM_LOGIN_CODE = `${BACKEND_URL_BASE}/token/random-code/new/local`;


export const GET_MY_PROFILE = `${BACKEND_URL_BASE}/user/profile/me`;
export const START_EMAIL_VERIFICATION = `${BACKEND_URL_BASE}/user/email-verification-process/new`;
export const CHANGE_PASSWORD = `${BACKEND_URL_BASE}/user/password/update/local`;


export const GEN_FORGET_PASSWORD_VERIFY_CODE = `${BACKEND_URL_BASE}/user/password/forget-password-verify-code/new`;
export const VALIDATE_FORGET_PASSWORD_VERIFY_CODE = `${BACKEND_URL_BASE}/user/password/forget-password-verify-code/validate`;
export const RESET_PASSWORD_IN_FORGET_FLOW = `${BACKEND_URL_BASE}/user/password/forget-password-flow/update`;

//to-do-list
export const GET_MY_TODO_ITEMS = `${BACKEND_URL_BASE}/todo/items`;
export const NEW_TODO_ITEM = `${BACKEND_URL_BASE}/todo/items`;
export const getFinishToDoItemUrl = id => `${BACKEND_URL_BASE}/todo/items/${id}/finish`;
export const getSingleToDoItemUrl = id => `${BACKEND_URL_BASE}/todo/items/${id}`;
export const getUpdateToDoItemUrl = id => `${BACKEND_URL_BASE}/todo/items/${id}`;