import {loadingStarted} from "../../../global/GlobalActions";
import {GEN_FORGET_PASSWORD_VERIFY_CODE, RESET_PASSWORD_IN_FORGET_FLOW, VALIDATE_FORGET_PASSWORD_VERIFY_CODE} from "../../../global/BackendUrls";
import axios from "axios/index";
import {handleFetchError, handleFetchSuccess} from "../../../global/GlobalHandlers";

export function requestVerifyCodeAsyncAction(email, additionalCallback) {
    console.log(`additionalCallback is ${additionalCallback}`);

    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            GEN_FORGET_PASSWORD_VERIFY_CODE,

            JSON.stringify({
                email: email
            }),

            {headers: {'Content-Type': 'application/json'}}
        );

        promise
            .then(() => handleFetchSuccess(dispatch, "A verification code has been sent to your email address"))
            .then(additionalCallback)
            .catch((error) => handleFetchError(error, dispatch));
    }
    return actionAsFunction;  //so the action itself is a function
}


export function validateVerifyCodeAsyncAction(email, verifyCode, additionalCallback) {
    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            VALIDATE_FORGET_PASSWORD_VERIFY_CODE,

            JSON.stringify({
                email: email,
                verifyCode: verifyCode,
            }),

            {headers: {'Content-Type': 'application/json'}}
        );

        promise.then(() => handleFetchSuccess(dispatch, ''))
            .then(additionalCallback)
            .catch((error) => handleFetchError(error, dispatch, true));
    }
    return actionAsFunction;  //so the action itself is a function
}


export function resetPasswordAsyncAction(email, verifyCode, newPassword, successMsg, additionalCallback) {
    const actionAsFunction = function (dispatch) {
        loadingStarted(dispatch);

        const promise = axios.post(
            RESET_PASSWORD_IN_FORGET_FLOW,

            JSON.stringify({
                email: email,
                verifyCode: verifyCode,
                newPassword: newPassword
            }),

            {headers: {'Content-Type': 'application/json'}}
        );

        promise.then(function (response) {
            return response;
        })
            .then(() => handleFetchSuccess(dispatch, successMsg))
            .then(additionalCallback)
            .catch((error) => handleFetchError(error, dispatch, true));
    }
    return actionAsFunction;  //so the action itself is a function
}