import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({

    button: {
        width: "100%"
    },

    wrapper: {
        width: "100%",
        position: "relative"
    },

    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        //width and height are defined with "size" prop. See below
    },
});

//Note: variant="contained" will be forcefully applied to the button
class LoadingAwareButton extends React.Component {

    render() {

        const originalButtonProps = Object.assign({}, this.props);
        delete originalButtonProps.className;
        delete originalButtonProps.loading;
        delete originalButtonProps.classes;


        return (
            <div className={this.props.className}>
                <div className={this.props.classes.wrapper}>
                    <Button className={this.props.classes.button} {...originalButtonProps} variant="contained"
                            disabled={this.props.loading}/>
                    {this.props.loading && <CircularProgress size={24} className={this.props.classes.buttonProgress}/>}
                </div>
            </div>
        );

    }

};

LoadingAwareButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
}


export default withStyles(styles)(LoadingAwareButton);