import * as React from "react";
import {Redirect, Route} from 'react-router-dom'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {setPageTitleAction} from "./GlobalActions";
import {hasValidToken} from "../pages/auth/shared/TokenReducers";
import {PATH_LOGIN} from "./FrontendUrls";


//Do something in addition to React-Router's Route
class MyRouteComponent extends React.Component {

    render() {
        let {pageTitle, requireLogin, token, ...rest} = this.props;
        let loggedIn = hasValidToken(token);


        if (requireLogin && !loggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: PATH_LOGIN,
                        state: {from: this.props.location}
                    }}
                />
            );
        } else {
            return (<Route {...rest}/>);
        }
    }
}

MyRouteComponent.propTypes = {
    pageTitle: PropTypes.string,
    requireLogin: PropTypes.bool,
    token: PropTypes.object
}


const mapStateToProps = state => {
    return {
        token: state.token
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPageTitle: title => dispatch(setPageTitleAction(title))
    }
};

class MyRouteComponentContainer extends React.Component {
    componentDidMount() {
        this.refreshPageTitle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pageTitle !== this.props.pageTitle) { //without this you will see infinite loop
            this.refreshPageTitle();
        }
    }

    refreshPageTitle() {
        let pageTitle = this.props.pageTitle || "";
        this.props.setPageTitle(pageTitle);
    }

    render() {
        return <MyRouteComponent {...this.props}/>
    }
}

MyRouteComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyRouteComponentContainer);


export default MyRouteComponentContainer;